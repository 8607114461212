import React, { useContext, useEffect, useState } from "react";
import CourseDetails from "../Templates/Home/Courses/CourseDetails/CourseDetails";
import { courseURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";

function CourseDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${courseURL}/${id}`;
  const page = "programs";
  
  const [Open, setOpen] = useState(false);

  const [course, setCourse] = useState({});
  const { data, loading, error } = useFetch(url);
  
  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, page);

  useEffect(() => {
    if (!loading && !error && data) {
      setCourse(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "programs", data: course?.title });
  }, [course]);

  return (
    <CourseDetails
      Open={Open}
      setOpen={setOpen}
      course={course}
      setCourse={setCourse}
      url={url}
      setGo={setGo}
      loading={loading}
    />
  );
}

export default CourseDetailsPage;
