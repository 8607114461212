// const BaseURL = `http://localhost:4242`;
export const BaseURL = `https://api.skill-code.com`;

export const loginURL = `${BaseURL}/login`;
export const activateURL = `${BaseURL}/activate`;
export const changePasswordURL = `${BaseURL}/changePassword`;
export const logoutURL = `${BaseURL}/logout`;
export const schoolURL = `${BaseURL}/schools`;
export const teacherURL = `${BaseURL}/teachers`;
export const studentURL = `${BaseURL}/students`;
export const courseURL = `${BaseURL}/courses`;
export const classesURL = `${BaseURL}/classes`;
export const dashboardURL = `${BaseURL}/dashboard`;
export const countriesURL = `${BaseURL}/countries`;
export const listSchoolsURL = `${BaseURL}/schools?sort=name&fields=id,name`;
export const listTeachersURL = `${BaseURL}/teachers/list`;
export const listCoursesURL = `${BaseURL}/courses?sort=title&fields=id,title`;
export const listStudentsURL = `${BaseURL}/students/list`;
export const userInfo = `${BaseURL}/loggedInInfo`;

export const reportsURL = (id) => `${BaseURL}/classes/${id}/reports`;

export const sessionsClassesURL = (id) => `${BaseURL}/classes/${id}/sessions`;

export const reportsClassesURL = (id) => `${BaseURL}/classes/${id}/reports`;
