import React, { useContext, useEffect, useState } from "react";
import SchoolProfile from "../Templates/Home/Schools/SchoolProfile/SchoolProfile";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { classesURL, schoolURL } from "../Hooks/URL";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";

function SchoolProfilePage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${schoolURL}/${id}`;
  const statisticsUrl = `${schoolURL}/${id}/statistics`;
  const page = "schools";

  const [Open, setOpen] = useState(false);

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, page);

  const [school, setSchool] = useState({});
  const { data, loading, error } = useFetch(url);

  const [schoolNumbers, setSchoolNumbers] = useState({});
  const {
    data: SchoolNumbersData,
    loading: SchoolNumbersLoading,
    error: SchoolNumbersError,
  } = useFetch(statisticsUrl);

  const [classes, setClasses] = useState([]);
  const {
    data: classesData,
    loading: classesLoading,
    error: classesError,
  } = useFetch(`${classesURL}?sort=~start_date&limit=4&page=1&school_id=${id}`);

  const [goSave, setGoSave] = useState(false);
  const [activateClicked, setActivateClicked] = useState(false);
  const {
    saveState,
    loading: addLoading,
    newData,
  } = useSave({
    url: `${schoolURL}/${id}/resendActivation`,
    goSave,
    setGoSave,
  });
  
  useEffect(() => {
    if (!addLoading && saveState?.dataRes?.status) setActivateClicked(true);
  }, [addLoading, saveState]);

  useEffect(() => {
    if (!classesLoading && !classesError && classesData) {
      setClasses(classesData.data);
    }
  }, [classesData, classesLoading, classesError]);

  useEffect(() => {
    if (!SchoolNumbersError && !SchoolNumbersLoading && SchoolNumbersData) {
      setSchoolNumbers(SchoolNumbersData.data);
    }
  }, [SchoolNumbersData, SchoolNumbersLoading, SchoolNumbersError]);

  useEffect(() => {
    if (!loading && !error && data) {
      setSchool(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "schools", data: school?.name });
  }, [school]);

  return (
    <SchoolProfile
      Open={Open}
      setOpen={setOpen}
      school={school}
      setSchool={setSchool}
      url={url}
      setGo={setGo}
      loading={loading}
      role={contextData?.role}
      classes={classes}
      classesLoading={classesLoading}
      statistics={schoolNumbers}
      setGoSave={setGoSave}
      goSave={goSave}
      addLoading={addLoading}
      setActivateClicked={setActivateClicked}
      activateClicked={activateClicked}
    />
  );
}

export default SchoolProfilePage;
