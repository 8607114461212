import { useNavigate } from "react-router-dom";
import "./CourseDetailsCard.css";
import React from "react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import FlagIcon from "@mui/icons-material/Flag";

function CourseDetailsCard({ course }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="courseCard">
        <div>
          <h2>{course?.title}</h2>
          <h3>Description:</h3>
          <h3 className="classDetails">- {course?.description}</h3>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center classButton"}
            text={"see more"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "40px",
              padding: "0 15px",
              minWidth: "100px",
              textWrap: "noWrap",
              marginTop: "5px",
            }}
            click={() => navigate(`/programs/${course?.id}`)}
          />
        </div>
      </div>
    </>
  );
}

export default CourseDetailsCard;
