import "./ClassReport.css";
import React, { useState } from "react";
import ReportCard from "../../../../Components/Molecules/Cards/ReportCard/ReportCard";
import { PulseLoader } from "react-spinners";
import { Switch } from "@mui/material";

function ClassReport({
  classId,
  students,
  studentsLoading,
  reportsVisible,
  setReportsVisible,
  setGoEdit,
  setStudents,
  studentUrl,
}) {
  return (
    <div className="mainContainer">
      <div className="classDetailsCont cont">
        <h1 style={{ marginBottom: "15px" }}>Class Reports</h1>
        <div
          style={{
            marginBottom: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <label style={{ fontSize: "20px" }}>Show Reports</label>
          <Switch
            checked={reportsVisible}
            onChange={() => {
              setReportsVisible(!reportsVisible);
              setGoEdit(true);
            }}
          />
        </div>
        {!studentsLoading ? (
          students?.length ? (
            <>
              <div className="studentsCont">
                {students?.map((student) => (
                  <ReportCard
                    student={student}
                    classId={classId}
                    setStudents={setStudents}
                    studentUrl={studentUrl}
                  />
                ))}
              </div>
            </>
          ) : (
            <h2 style={{ textAlign: "center", margin: "50px" }}>
              No Students Enrolled!!
            </h2>
          )
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ClassReport;
