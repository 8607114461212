import React, { useContext, useEffect, useState } from "react";
import StudentProfile from "../Templates/Home/Students/StudentProfile/StudentProfile";
import { studentURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";

function StudentProfilePage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${studentURL}/${id}`;
  const page = "students";
  const statisticsUrl = `${studentURL}/${id}/statistics`;
  const classesUrl = `${studentURL}/${id}/classes`;

  const [Open, setOpen] = useState(false);

  const [student, setStudent] = useState({});
  const { data, loading, error } = useFetch(url);

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, page);

  const [studentNumbers, setStudentNumbers] = useState({});
  const [classes, setClasses] = useState([]);
  const {
    data: StudentNumbersData,
    loading: StudentNumbersLoading,
    error: StudentNumbersError,
  } = useFetch(statisticsUrl);
  const {
    data: classesData,
    loading: classesLoading,
    error: classesError,
  } = useFetch(classesUrl);

  useEffect(() => {
    if (!loading && !error && data) {
      setStudent(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!StudentNumbersError && !StudentNumbersLoading && StudentNumbersData) {
      setStudentNumbers(StudentNumbersData.data);
    }
  }, [StudentNumbersData, StudentNumbersLoading, StudentNumbersError]);

  useEffect(() => {
    if (!classesError && !classesLoading && classesData) {
      setClasses(classesData.data);
    }
  }, [classesData, classesLoading, classesError]);

  useEffect(() => {
    setContextData({ ...contextData, page: "students", data: student?.name });
  }, [student]);

  return (
    <StudentProfile
      Open={Open}
      setOpen={setOpen}
      student={student}
      setStudent={setStudent}
      url={url}
      setGo={setGo}
      loading={loading}
      statistics={studentNumbers}
      classes={classes}
      classesLoading={classesLoading}
      role={contextData?.role}
    />
  );
}

export default StudentProfilePage;
