import { useNavigate } from "react-router-dom";
import "./CourseCard.css";
import React from "react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import FlagIcon from "@mui/icons-material/Flag";

function CourseCard({
  class_id,
  class_name,
  school,
  course,
  teacher,
  terminated,
}) {
  const navigate = useNavigate();
  return (
    <>
      <div className="courseCard">
        <h2>
          {class_name} {terminated ? <FlagIcon /> : ""}
        </h2>
        <div>
          <h3>Program:</h3>
          <h3 className="classDetails">- {course}</h3>
          <h3>Teacher:</h3>
          <h3 className="classDetails">- {teacher}</h3>
          <h3>School:</h3>
          <h3 className="classDetails">- {school}</h3>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center classButton"}
            text={"see more"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "30px",
              padding: "0 15px",
              minWidth: "100px",
              textWrap: "noWrap",
              marginTop: "5px",
            }}
            click={() => navigate(`/classes/${class_id}`)}
          />
        </div>
      </div>
    </>
  );
}

export default CourseCard;
