import React, { useContext, useEffect, useState } from "react";
import Dashboard from "../Templates/Home/Dashboard/Dashboard";
import { userContext } from "../Contexts/userContext";
import useFetch from "../Hooks/useFetch";
import { classesURL, dashboardURL } from "../Hooks/URL";

function DashboardPage() {
  const { contextData, setContextData } = useContext(userContext);

  const [statistics, setStatistics] = useState("");
  const { data, loading, error } = useFetch(dashboardURL);

  const [classes, setClasses] = useState([]);
  const {
    data: classesData,
    loading: classesLoading,
    error: classesError,
  } = useFetch(`${classesURL}?sort=~start_date&limit=4&page=1`);

  useEffect(() => {
    if (!classesLoading && !classesError && classesData) {
      setClasses(classesData.data);
    }
  }, [classesData, classesLoading, classesError]);

  useEffect(() => {
    if (!loading && !error && data) {
      setStatistics(data?.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "dashboard" });
  }, []);

  return (
    <Dashboard
      contextData={contextData}
      statistics={statistics}
      loading={loading}
      classes={classes}
      classesLoading={classesLoading}
    />
  );
}

export default DashboardPage;
