import "./ClassFeedback.css";
import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import "react-international-phone/style.css";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import TextArea from "../../../../Components/Atoms/Inputs/TextArea/TextArea";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon color="error" className="reportIcon" />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" className="reportIcon" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" className="reportIcon" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" className="reportIcon" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" className="reportIcon" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const FeedbackSchema = Yup.object().shape({
  summary: Yup.string().required("Required !!"),
});

function ClassFeedback({
  studentData,
  attributes,
  reportData,
  reportLoading,
  loading,
  studentLoading,
  setGoSave,
  classId,
  studentId,
  addLoading,
  editLoading,
  setNewObject,
  Edit,
  setGoEdit,
}) {
  const navigate = useNavigate();
  const [attrs, setAttr] = useState([]);

  useEffect(() => {
    if (attributes?.length && !Edit) {
      setAttr(
        attributes?.map((att) => ({
          id: att?.id,
          name: att?.attribute,
          value: 0,
        }))
      );
    }
  }, [attributes]);

  useEffect(() => {
    if (reportData && !reportLoading && Edit) {
      setAttr(
        reportData?.attributes?.map((att) => ({
          id: att?.attribute_id,
          name: att?.attribute,
          value: att?.value,
        }))
      );
    }
  }, [reportData]);

  return (
    <div className="mainContainer">
      {((reportData && Edit) || !Edit) && !loading ? (
        <div className="classDetailsCont cont">
          <h1 style={{ marginBottom: "20px" }}>{studentData?.name} Report</h1>
          <Formik
            initialValues={{
              summary: Edit ? reportData?.summary : "",
            }}
            validationSchema={FeedbackSchema}
            onSubmit={(values) => {
              setNewObject({
                ...values,
                attributes: attrs?.map((item) => ({
                  attribute: item?.id,
                  value: item?.value,
                })),
              });
              Edit ? setGoEdit(true) : setGoSave(true);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div>
                  <br />
                  {attributes && !loading ? (
                    <>
                      <div
                        className="attributeBox attrTitle"
                        style={{ marginTop: "20px", width: "100%" }}
                      >
                        <span style={{ width: "20%" }}>Attribute</span>
                        <span className="MuiRating-root">
                          <span
                            className="attrTitle"
                            style={{ width: "20%", paddingLeft: "18px" }}
                          >
                            Poor
                          </span>
                          <span
                            className="attrTitle"
                            style={{ width: "20%", paddingLeft: "22px" }}
                          >
                            Fair
                          </span>
                          <span
                            className="attrTitle"
                            style={{ width: "20%", paddingLeft: "14px" }}
                          >
                            Good
                          </span>
                          <span
                            className="attrTitle"
                            style={{ width: "20%", paddingLeft: "0" }}
                          >
                            Very Good
                          </span>
                          <span
                            className="attrTitle"
                            style={{ width: "20%", paddingLeft: "0" }}
                          >
                            Excellent
                          </span>
                        </span>
                      </div>
                      {(attrs?.length && reportData && Edit) || !Edit
                        ? attributes?.map((item) => (
                            <div
                              className="attributeBox"
                              style={{ width: "100%" }}
                            >
                              <h2>{item?.attribute}</h2>
                              <StyledRating
                                name="highlight-selected-only"
                                IconContainerComponent={IconContainer}
                                getLabelText={(value) =>
                                  customIcons[value].label
                                }
                                highlightSelectedOnly
                                value={
                                  attrs?.find((att) => att.id == item?.id)
                                    ?.value
                                }
                                onChange={(e) => {
                                  setAttr([
                                    ...attrs?.filter(
                                      (attrib) => attrib?.id != item?.id
                                    ),
                                    {
                                      ...attrs?.find(
                                        (attrib) => attrib?.id == item?.id
                                      ),
                                      value: e.target.value,
                                    },
                                  ]);
                                }}
                              />
                            </div>
                          ))
                        : ""}
                      <h3 style={{ marginTop: "60px" }}>Note:</h3>
                      <TextArea
                        classes="textAreaInputFull "
                        label={"Note"}
                        name={"summary"}
                        newValue={values.summary}
                        change={handleChange}
                        blur={handleBlur}
                      />
                      {errors.summary && touched.summary && errors.summary && (
                        <p className="error">
                          {errors.summary && touched.summary && errors.summary}
                        </p>
                      )}
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <PrimaryButton
                          classBtn={"center addButton"}
                          btnType={"submit"}
                          text={Edit ? "Edit" : "Submit"}
                          loading={Edit ? editLoading : addLoading}
                          style={{
                            textTransform: "capitalize",
                            marginTop: "15px",
                            width: "200px",
                            borderRadius: "5px",
                            color: "var(--text-secondary)",
                            backgroundColor: "var(--primary)",
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="loadingBox">
                      <PulseLoader color="#06abc9" />
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      ) : (
        <div className="loadingBox">
          <PulseLoader color="#06abc9" />
        </div>
      )}
    </div>
  );
}

export default ClassFeedback;
