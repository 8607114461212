import React, { useEffect, useState } from "react";
import "./AddCourseForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";
import TextArea from "../../../Atoms/Inputs/TextArea/TextArea";
import useEdit from "../../../../Hooks/useEdit";
import useSave from "../../../../Hooks/useSave";
import { courseURL } from "../../../../Hooks/URL";
import AddIcon from "@mui/icons-material/Add";
import { Formik } from "formik";
import * as Yup from "yup";
import { images } from "../../../../Assets/assetsHelper";
import CloseIcon from "@mui/icons-material/Close";

const CourseSchema = Yup.object().shape({
  title: Yup.string().required("Program name required !!"),
  description: Yup.string().required("Description required !!"),
});

function AddCourseForm({
  Edit = false,
  course,
  url,
  setCourse,
  setOpen,
  setCourses,
  search,
  page,
}) {
  // const [file, setFile] = useState("");
  const [newObject, setNewObject] = useState({});
  const [attributes, setAttribute] = useState(
    course?.attributes?.map((att) => att.attribute) ?? []
  );
  const [goEdit, setGoEdit] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const [status, setStatus] = useState(course?.status || "");
  const { state, loading: editLoading } = useEdit(
    url,
    goEdit,
    setGoEdit,
    newObject,
    false,
    false,
    { function: setCourse }
  );
  const { saveState, loading: addLoading } = useSave({
    url: courseURL,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setCourses,
      page: page,
      limit: 10,
      search: search,
    },
  });

  // const handleUploadClick = (e) => {
  //   setNewObject({
  //     ...newObject,
  //     logo: URL.createObjectURL(e.target.files[0]),
  //   });
  //   setFile(URL.createObjectURL(e.target.files[0]));
  // };

  useEffect(() => {
    if (state.dataRes.status == "success") {
      setOpen(false);
    }
  }, [state]);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        initialValues={{
          title: Edit ? course?.title ?? "" : "",
          description: Edit ? course?.description ?? "" : "",
        }}
        validationSchema={CourseSchema}
        onSubmit={(values) => {
          if (course) {
            const prev = {
              title: course?.title ?? "",
              description: course?.description ?? "",
              attributes: course?.attributes?.map((att) => att.attribute) ?? [],
            };
            const newObj = { ...values, attributes: attributes };
            setNewObject(
              Object.fromEntries(
                Object.entries({
                  ...prev,
                  ...newObj,
                }).filter(([key]) => prev[key] != newObj[key])
              )
            );
            setGoEdit(true);
          } else {
            setNewObject({ ...values, attributes: attributes });
            setGoSave(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="CourseFormContainer">
              <div className="LeftForm">
                {/*  <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                     <Typography
                      style={{ fontWeight: "500", marginBottom: "10px" }}
                    >
                      Program Logo:
                    </Typography>
                    <Button
                      component="label"
                      variant="contained"
                      style={{ marginBottom: "20px" }}
                      startIcon={<CloudUploadIcon />}
                      className="ImageInputButton"
                    >
                      Upload file
                      <input
                        accept="image/*"
                        className={"ImageInput"}
                        id="contained-button-file"
                        type="file"
                        onChange={handleUploadClick}
                      />
                    </Button>
                  </div>
                  <img
                    src={file}
                    style={{
                      width: "120px",
                      maxHeight: "120px",
                    }}
                  />
                </div>*/}
                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter Program Name"}
                  className="Input"
                  label={"Program Name"}
                  name={"title"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.title}
                />
              </div>
              {errors.title && touched.title && errors.title && (
                <p className="error">
                  {errors.title && touched.title && errors.title}
                </p>
              )}

              <div className="RightForm">
                {Edit ? (
                  ""
                ) : (
                  <>
                    <TextFieldInput
                      Required={false}
                      fullWidth
                      type="text"
                      setAttribute={setAttribute}
                      attribute={attributes || []}
                      EndAdornment={<AddIcon />}
                      placeholder={"Enter Program Attribute Report"}
                      className="Input"
                      label={"Attribute Report"}
                      name={"report"}
                    />
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        width: "100%",
                        flexWrap: "wrap",
                      }}
                    >
                      {attributes.map((a, index) => (
                        <p
                          key={index}
                          style={{
                            padding: "15px 10px 10px",
                            borderRadius: "4px",
                            background: "#06abc9",
                            color: "white",
                            position: "relative",
                            cursor: "pointer",
                            marginBottom: "5px",
                          }}
                          onClick={() => {
                            const updatedAttribute = attributes.filter(
                              (_, idx) => idx !== index
                            );
                            setAttribute(updatedAttribute);
                          }}
                        >
                          {a}
                          <div
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: "5px",
                              top: "-2px",
                            }}
                            onClick={() => {
                              const updatedAttribute = attributes.filter(
                                (_, idx) => idx !== index
                              );
                              setAttribute(updatedAttribute);
                            }}
                          >
                            x
                          </div>
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            <label className="aboutLabel">About The Program:</label>
            <TextArea
              classes="textAreaInput"
              label={"Description"}
              name={"description"}
              change={handleChange}
              blur={handleBlur}
              newValue={values.description}
            />
            {errors.description &&
              touched.description &&
              errors.description && (
                <p className="error">
                  {errors.description &&
                    touched.description &&
                    errors.description}
                </p>
              )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={Edit ? "Edit" : "Add"}
                loading={Edit ? editLoading : addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddCourseForm;
