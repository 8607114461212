import React, { useContext, useEffect, useState } from "react";
import Schools from "../Templates/Home/Schools/Schools";
import useFetch from "../Hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { schoolURL } from "../Hooks/URL";
import { userContext } from "../Contexts/userContext";
import Fetch from "../Hooks/Fetch";

function SchoolsPage() {
  const { contextData, setContextData } = useContext(userContext);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchData, setSearchData] = useState("");
  const [search, setSearch] = useState("");
  const [Filter, setFilter] = useState("");
  const [Open, setOpen] = useState(false);

  const [schools, setSchools] = useState([]);
  const [schoolsLoading, setSchoolsLoading] = useState(false);
  const { data, loading, error } = useFetch(
    schoolURL,
    page,
    rowsPerPage,
    search,
    null,
    null,
    Filter
  );

  useEffect(() => {
    if ((!schoolsLoading || !loading) && !error && data) {
      setSchools(data?.data);
      setPageCount(data?.pagesCount);
    }
  }, [data, schoolsLoading, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "schools", data: "" });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Schools
      page={page}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      searchData={searchData}
      search={search}
      setSearchData={setSearchData}
      setSearch={setSearch}
      schools={schools}
      setSchools={setSchools}
      navigate={navigate}
      handleChangePage={handleChangePage}
      Open={Open}
      setOpen={setOpen}
      loading={loading || schoolsLoading}
      data={data}
      error={error}
      pageCount={pageCount}
      setSchoolsLoading={setSchoolsLoading}
      setFilter={setFilter}
    />
  );
}

export default SchoolsPage;
