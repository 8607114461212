import { images } from "../../../../Assets/assetsHelper";
import "./TeacherProfile.css";
import React, { useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddTeacherForm from "../../../../Components/Organisms/Forms/AddTeacherForm/AddTeacherForm";
import { BaseURL } from "../../../../Hooks/URL";
import Person3Icon from "@mui/icons-material/Person3";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import Groups2Icon from "@mui/icons-material/Groups2";
import ClassIcon from "@mui/icons-material/Class";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

function TeacherProfile({
  Open,
  setOpen,
  teacher,
  setTeacher,
  url,
  setGo,
  loading,
  statistics,
  role,
  addLoading,
  setActivateClicked,
  activateClicked,
  setGoSave,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <FormModal
        Open={Open}
        HandleClose={() => {
          setOpen(false);
        }}
        Form={
          <AddTeacherForm
            Edit={true}
            Open={Open}
            setOpen={setOpen}
            teacher={teacher}
            url={url}
            setTeacher={setTeacher}
          />
        }
        Title={"Edit Instructor"}
      />
      <div className="TeacherCont">
        {teacher && !loading ? (<div className="EditDeleteHeader" style={{ flexWrap: "wrap", margin: "0 0 20px" }}>
          {teacher?.activated || activateClicked ? (
            ""
          ) : (
            <PrimaryButton
              classBtn={"center editButton"}
              text={"Resend Verification"}
              loading={addLoading}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 20px",
                margin: "0 20px",
                width: "max-content",
              }}
              click={() => {
                setGoSave(true);
              }}
            />
          )}
          <PrimaryButton
            classBtn={"center editButton"}
            text={"Edit"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "45px",
              padding: "0 30px ",
              margin: "0 20px 0 0",
            }}
            click={() => {
              setOpen(true);
            }}
          />
          <DeleteForm
            setGo={setGo}
            title={"Are you sure to delete this school ?"}
          />
        </div>) : ""}
        <div className="greyBox">
          <div className="detailsHeaderBox">
            <div className="detailsHeaderBoxInside">
              <img
                alt="photo"
                className="SchoolLogo"
                src={
                  teacher?.photo
                    ? `${BaseURL}/images/${teacher?.photo}`
                    : images.TeacherPlace
                }
              />
              <div className="SchoolDetails">
                <h2 style={{ fontSize: "35px", margin: "0 0 10px 0" }}>
                  {teacher.name}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="greyBoxWhite">
          {/* <div className="greyNumberBox">
            <Groups2Icon className="numbersIcon" />
            <div>
              <h4>STUDENTS</h4>
              <h2>{statistics?.counting?.at(0)?.number_of_students}</h2>
            </div>
          </div> */}
          <div className="greyNumberBox">
            <ClassIcon className="numbersIcon" />
            <div>
              <h4>CLASSES</h4>
              <h2>{statistics?.counting?.at(0)?.number_of_classes}</h2>
            </div>
          </div>
        </div>
        <div className="greyBoxVer">
          <h3>Email:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {teacher?.email}
          </p>
          <h3 style={{ marginTop: "20px" }}>Phone Number:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {teacher?.phone}
          </p>
          <h3 style={{ marginTop: "20px" }}>Gender:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {teacher?.gender == 1 ? "Male" : "Female"}
          </p>
          <h3 style={{ marginTop: "20px" }}>National ID:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {teacher?.national_id}
          </p>
          <h3
            style={{ marginTop: "20px", display: "flex", alignItems: "center" }}
          >
            Is Activated:{" "}
            {teacher?.activated ? (
              <DoneIcon className="activatedTick" />
            ) : (
              <CloseIcon className="NonActivatedTick" />
            )}
          </h3>
          {role == 1 ? (
            <>
              <h3 style={{ margin: "20px 0" }}>School Hire From:</h3>
              {role == 1 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt="Logo"
                    style={{ width: "50px", margin: "0 5px" }}
                    src={
                      teacher?.school
                        ? `${BaseURL}/images/${teacher?.school?.logo}`
                        : images.Company_Logo
                    }
                  />
                  <p
                    style={{
                      maxWidth: "500px",
                      fontSize: "20px",
                    }}
                  >
                    {teacher?.school ? teacher?.school?.name : "Skill Code"}
                  </p>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default TeacherProfile;
