import React, { useEffect, useState } from "react";
import "./ChangePasswordForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { activateURL, changePasswordURL } from "../../../../Hooks/URL";
import useActivate from "../../../../Hooks/useActivate";
import useSave from "../../../../Hooks/useSave";
import { useCookies } from "react-cookie";

const ActivateSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(8, "Password is too short !!")
    .test(
      "isValidPass",
      "Password must contain at least 1 number, 1 uppercase, 1 lowercase, 1 special character !!",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbol = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    )
    .required("Password required !!"),
  password: Yup.string()
    .min(8, "Password is too short !!")
    .test(
      "isValidPass",
      "Password must contain at least 1 number, 1 uppercase, 1 lowercase, 1 special character !!",
      (value, context) => {
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSymbol = /[!@#%&]/.test(value);
        let validConditions = 0;
        const numberOfMustBeValidConditions = 4;
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol];
        conditions.forEach((condition) =>
          condition ? validConditions++ : null
        );
        if (validConditions >= numberOfMustBeValidConditions) {
          return true;
        }
        return false;
      }
    )
    .required("Password required !!"),
  passwordConfirm: Yup.string()
    .min(8, "Password is too short !!")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password required !!"),
});

function ChangePasswordForm({ handleClose }) {
  const [t, i18n] = useTranslation("common");
  const [cookies, setCookie] = useCookies(["TOKEN"]);
  const [newObject, setNewObject] = useState({});
  const [goSave, setGoSave] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const { saveState, loading } = useSave({
    url: `${changePasswordURL}`,
    goSave,
    setGoSave,
    newObject,
  });

  useEffect(() => {
    if (saveState?.dataRes?.token) {
      setCookie("TOKEN", saveState?.dataRes?.token, { path: "/" });
      handleClose();
    }
  }, [saveState]);

  return (
    <div className="FormWrapper">
      <Formik
        initialValues={{
          currentPassword: "",
          password: "",
          passwordConfirm: "",
        }}
        validationSchema={ActivateSchema}
        onSubmit={(values) => {
          setNewObject(values);
          setGoSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form
            className="LoginFormContainer"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <TextFieldInput
              fullWidth
              name="currentPassword"
              type="password"
              placeholder={"Enter your current password"}
              className="LoginInput"
              label="Password"
              change={handleChange}
              blur={handleBlur}
              newValue={values.currentPassword}
            />
            {errors.currentPassword &&
              touched.currentPassword &&
              errors.currentPassword && (
                <p className="error">
                  {errors.currentPassword &&
                    touched.currentPassword &&
                    errors.currentPassword}
                </p>
              )}

            <TextFieldInput
              fullWidth
              name="password"
              type="password"
              placeholder={"Enter a new password"}
              className="LoginInput"
              label="Confirm Password"
              change={handleChange}
              blur={handleBlur}
              newValue={values.password}
            />
            {errors.password && touched.password && errors.password && (
              <p className="error">
                {errors.password && touched.password && errors.password}
              </p>
            )}

            <TextFieldInput
              fullWidth
              name="passwordConfirm"
              type="password"
              placeholder={"Enter your password again"}
              className="LoginInput"
              label="Confirm Password"
              change={handleChange}
              blur={handleBlur}
              newValue={values.passwordConfirm}
            />
            {errors.passwordConfirm &&
              touched.passwordConfirm &&
              errors.passwordConfirm && (
                <p className="error">
                  {errors.passwordConfirm &&
                    touched.passwordConfirm &&
                    errors.passwordConfirm}
                </p>
              )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Submit"}
                loading={loading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "50%",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePasswordForm;
