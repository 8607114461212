import "./Home.css";
import React, { useState } from "react";
import SideBar from "../../Components/Organisms/Bars/SideBar/SideBar";
import NavBar from "../../Components/Organisms/Bars/NavBar/NavBar";
import { Link, Route, Routes } from "react-router-dom";
import SchoolsPage from "../../Pages/SchoolsPage";
import DashboardPage from "../../Pages/DashboardPage";
import SchoolProfilePage from "../../Pages/SchoolProfilePage";
import TeachersPage from "../../Pages/TeachersPage";
import TeacherProfilePage from "../../Pages/TeacherProfilePage";
import CoursesPage from "../../Pages/CoursesPage";
import CourseDetailsPage from "../../Pages/CourseDetailsPage";
import StudentsPage from "../../Pages/StudentsPage";
import StudentProfilePage from "../../Pages/StudentProfilePage";
import CloseIcon from "@mui/icons-material/Close";
import ClassesPage from "../../Pages/ClassesPage";
import ClassesDetailsPage from "../../Pages/ClassesDetailsPage";
import ClassReportPage from "../../Pages/ClassReportPage";
import ClassFeedbackPage from "../../Pages/ClassFeedbackPage";
import { useCookies } from "react-cookie";
import useLogout from "../../Hooks/useLogout";
import { logoutURL } from "../../Hooks/URL";
import PrimaryButton from "../../Components/Atoms/Buttons/Primary/PrimaryButton";
import ClassSessionsPage from "../../Pages/ClassSessionsPage";
import ClassSessionDetailsPage from "../../Pages/ClassSessionDetailsPage";
import ProfilePage from "../../Pages/ProfilePage";

function Home() {
  const [showMenu, setShowMenu] = useState(false);
  const [cookies, setCookie] = useCookies(["TOKEN"]);
  const [goSave, setGoSave] = useState(false);
  function handleShowMenu() {
    setShowMenu(!showMenu);
  }
  const { saveState } = useLogout({
    url: logoutURL,
    goSave,
    setGoSave,
  });

  return (
    <div className="Home">
      <NavBar showMenu={showMenu} setShowMenu={setShowMenu} />
      <div className="container">
        <div className={`sidebar ${showMenu ? "sidebarOpen" : "sidebarClose"}`}>
          <span
            className={"closeIcon mobile " + (showMenu ? "" : "hidden")}
            onClick={() => handleShowMenu()}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              padding: "0 20px",
            }}
          >
            {cookies.TOKEN ? (
              <PrimaryButton
                classBtn={"center mobile"}
                text={"Logout"}
                click={() => {
                  setGoSave(true);
                  // setShowMenu(false);
                }}
                style={{
                  textTransform: "capitalize",
                  width: "100px",
                  borderRadius: "5px",
                  color: "#068399",
                  backgroundColor: "white",
                  height: "45px",
                }}
              />
            ) : (
              <Link
                to="/Login"
                className="login"
                onClick={() => setShowMenu(false)}
              >
                <PrimaryButton
                  classBtn={"center mobile"}
                  text={"Login"}
                  style={{
                    textTransform: "capitalize",
                    width: "100px",
                    borderRadius: "5px",
                    color: "#068399",
                    backgroundColor: "white",
                  height: "45px",
                  }}
                />
              </Link>
            )}
            <CloseIcon
              sx={{
                fontSize: 30,
                marginBottom: 2,
                marginLeft: 2,
                cursor: "pointer",
              }}
            />
          </span>
          <SideBar setShowMenu={setShowMenu} />
        </div>
        <div className="main">
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route exact path="/schools" element={<SchoolsPage />} />
            <Route path="/schools/:id" element={<SchoolProfilePage />} />
            <Route path="/instructors" element={<TeachersPage />} />
            <Route path="/instructors/:id" element={<TeacherProfilePage />} />
            <Route path="/programs" element={<CoursesPage />} />
            <Route path="/programs/:id" element={<CourseDetailsPage />} />
            <Route path="/students" element={<StudentsPage />} />
            <Route path="/students/:id" element={<StudentProfilePage />} />
            <Route path="/classes" element={<ClassesPage />} />
            <Route path="/classes/:id" element={<ClassesDetailsPage />} />
            <Route path="/classes/:id/report" element={<ClassReportPage />} />
            <Route
              path="/classes/:id/sessions"
              element={<ClassSessionsPage />}
            />
            <Route
              path="/classes/:id/sessions/:session_id"
              element={<ClassSessionDetailsPage />}
            />
            <Route
              path="/classes/:id/report/:studentId"
              element={<ClassFeedbackPage />}
            />
            <Route path="/account" element={<ProfilePage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Home;
