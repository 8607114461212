import "./Students.css";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import AddStudentForm from "../../../Components/Organisms/Forms/AddStudnetForm/AddStudentForm";
import { PulseLoader } from "react-spinners";
import { BaseURL } from "../../../Hooks/URL";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import { images } from "../../../Assets/assetsHelper";
import Filter from "../../../Components/Molecules/Filter/Filter";
import { Box, Grid, Pagination, Typography } from "@mui/material";
import CourseCard from "../../../Components/Molecules/Cards/CourseCard/CourseCard";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06abc9",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const adminColumns = [
  {
    id: "photo",
    label: "",
    minWidth: 50,
  },
  { id: "name", label: "Name", minWidth: 150 },
  {
    id: "birth_day",
    label: "Birthday",
    minWidth: 170,
  },
  {
    id: "gender",
    label: "Gender",
    minWidth: 70,
  },
  {
    id: "national_id",
    label: "National ID",
    minWidth: 170,
  },
  {
    id: "school_name",
    label: "School Hire From",
    minWidth: 170,
  },
];

const columns = [
  {
    id: "photo",
    label: "",
    minWidth: 50,
  },
  { id: "name", label: "Name", minWidth: 150 },
  {
    id: "birth_day",
    label: "Birthday",
    minWidth: 100,
  },
  {
    id: "gender",
    label: "Gender",
    minWidth: 100,
  },
  {
    id: "national_id",
    label: "National ID",
    minWidth: 100,
  },
];

function Students({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  searchData,
  search,
  setSearchData,
  setSearch,
  students,
  navigate,
  handleChangePage,
  Open,
  setOpen,
  loading,
  data,
  error,
  pageCount,
  setStudents,
  setFilter,
  role,
}) {
  return (
    <div className="mainContainer">
      <div className="cont">
        <FormModal
          Open={Open}
          HandleClose={() => setOpen(false)}
          Form={
            <AddStudentForm
              Edit={false}
              setOpen={setOpen}
              Open={Open}
              setStudents={setStudents}
              page={page}
              search={search}
            />
          }
          Title={"Add New Student"}
        />
        <div className="header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextFieldInput
              searchIcon={true}
              Required={false}
              type="text"
              placeholder={"Search"}
              className="SearchBar"
              name={"search"}
              change={(e) => setSearchData(e.target.value)}
              newValue={searchData}
              searchAction={() => {
                if (search != searchData) {
                  setSearch(searchData);
                }
              }}
              keyDown={(e) => {
                if (e.keyCode === 13)
                  if (search != searchData) {
                    setSearch(searchData);
                  }
              }}
            />
            <Filter Gender schools setFilter={setFilter} />
          </div>
          {role == 1 || role == 2 ? (
            <PrimaryButton
              classBtn={"center addButton"}
              text={"Add Student"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 30px",
                minWidth: "100px",
                textWrap: "noWrap",
              }}
              click={() => setOpen(true)}
            />
          ) : (
            ""
          )}
        </div>
        {students && !loading ? (
          <>
            <Grid container sx={{ width: "100%" }}>
              {students.map((student) => (
                <Grid item xs={6} sm={4} md={3} lg={12 / 5}>
                  <Box
                    sx={{
                      background: "white",
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      borderRadius: "10px",
                      height: "calc(100% - 20px)",
                      m: "10px",
                      cursor: "pointer",
                      ":hover": {
                        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.3)",
                        transform: "translateY(1px)",
                      },
                    }}
                    onClick={() => {
                      navigate(`/students/${student.id}`);
                    }}
                  >
                    <img
                      alt="photo"
                      width={"80%"}
                      src={
                        student?.photo
                          ? `${BaseURL}/images/${student?.photo}`
                          : images.StudentPlace
                      }
                      style={{
                        margin: "auto",
                        minHeight: "80px",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "block",
                        fontSize: { xs: "15px", md: "18px" },
                        textAlign: "center",
                        fontWeight: "600",
                        marginTop: "5px",
                      }}
                    >
                      {student?.name}
                    </Typography>
                    {role == 1 ? (
                      <Typography
                        sx={{
                          display: "block",
                          fontSize: { xs: "14px", md: "16px" },
                          textAlign: "center",
                        }}
                      >
                        school: {student?.school_name}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
            {!students?.length ? (
              <h3 style={{ textAlign: "center", margin: "50px" }}>
                No Students Added Yet!!
              </h3>
            ) : (
              ""
            )}
            <Pagination
              className="paginationCount"
              count={pageCount}
              page={page + 1}
              onChange={handleChangePage}
              size="large"
            />
          </>
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
        {/* <Paper
          className="tableCont"
          sx={{ width: "fit-content", minWidth: "100%" }}
        >
          {students && !loading ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {role == 1
                        ? adminColumns.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                              }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))
                        : columns.map((column) => (
                            <StyledTableCell
                              key={column.id}
                              align={column.align}
                              style={{
                                minWidth: column.minWidth,
                              }}
                            >
                              {column.label}
                            </StyledTableCell>
                          ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map((student) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={student?.code}
                          onClick={() => {
                            navigate(`/students/${student.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {role == 1
                            ? adminColumns.map((column) => {
                                const value = student[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ padding: "10px" }}
                                  >
                                    {column.id === "photo" ? (
                                      <img
                                        alt="photo"
                                        width={"50px"}
                                        height={"50px"}
                                        src={
                                          value
                                            ? `${BaseURL}/images/${value}`
                                            : images.StudentPlace
                                        }
                                        style={{
                                          margin: "auto",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    ) : (
                                      <>
                                        {column.id === "gender" && value ? (
                                          <>{value == 1 ? "Male" : "Female"}</>
                                        ) : (
                                          <>
                                            {column.id === "birth_day" && value
                                              ? new Date(
                                                  value
                                                ).toLocaleDateString()
                                              : value}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                );
                              })
                            : columns.map((column) => {
                                const value = student[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ padding: "10px" }}
                                  >
                                    {column.id === "photo" ? (
                                      <img
                                        alt="photo"
                                        width={"50px"}
                                        height={"50px"}
                                        src={
                                          value
                                            ? `${BaseURL}/images/${value}`
                                            : images.StudentPlace
                                        }
                                        style={{
                                          margin: "auto",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    ) : (
                                      <>
                                        {column.id === "gender" && value ? (
                                          <>{value == 1 ? "Male" : "Female"}</>
                                        ) : (
                                          <>
                                            {column.id === "birth_day" && value
                                              ? new Date(
                                                  value
                                                ).toLocaleDateString()
                                              : value}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </TableCell>
                                );
                              })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!students?.length ? (
                  <h3 style={{ textAlign: "center", margin: "50px" }}>
                    No Students Added Yet!!
                  </h3>
                ) : (
                  ""
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </Paper> */}
      </div>
    </div>
  );
}

export default Students;
