import "./ClassSessionDetails.css";
import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddTaskForm from "../../../../Components/Organisms/Forms/AddTaskForm/AddTaskForm";
import { useLocation } from "react-router-dom";
import { BaseURL } from "../../../../Hooks/URL";

function ClassSessionDetails({
  sessionData,
  loading,
  taskOpen,
  setTaskOpen,
  url,
  setGo,
  setGoTask,
  DeleteLoading,
  TaskDeleteLoading,
  setSessionData,
  FetchUrl,
}) {
  const location = useLocation();
  return (
    <div className="mainContainer">
      <FormModal
        Open={taskOpen}
        HandleClose={() => setTaskOpen(false)}
        Form={
          <AddTaskForm
            setOpen={setTaskOpen}
            url={url}
            setSessionData={setSessionData}
            FetchUrl={FetchUrl}
          />
        }
        Title={"Add Task"}
      />
      <div className="classDetailsCont cont">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h1>Session {location.state.sessionNo}</h1>
          <div>
            {!sessionData?.class_is_terminated ? (
              <PrimaryButton
                classBtn={"center studentButton deleteBtn"}
                text={"Delete Session"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "#D11A2A",
                  height: "45px",
                  padding: "0 30px",
                  minWidth: "100px",
                  textWrap: "noWrap",
                }}
                loading={DeleteLoading}
                click={() => setGo(true)}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {sessionData && !loading ? (
          <>
            <h2 style={{ margin: "20px 0 10px" }}>
              - Session Date: {sessionData?.date_time?.split("T")?.at(0)}
            </h2>
            <h2 style={{ marginBottom: "10px" }}>- Session Content:</h2>
            <h3 style={{ margin: "10px 0" }}>
              {sessionData?.content ?? "No content yet.."}
            </h3>
          </>
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
      </div>
      <div className="classDetailsCont cont">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h1>Session Task: </h1>
          {!sessionData?.class_is_terminated ? (
            sessionData?.task ? (
              <PrimaryButton
                classBtn={"center studentButton deleteBtn"}
                text={"Delete Task"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "#D11A2A",
                  height: "45px",
                  padding: "0 30px",
                  minWidth: "100px",
                  textWrap: "noWrap",
                }}
                loading={TaskDeleteLoading}
                click={() => setGoTask(true)}
              />
            ) : (
              <PrimaryButton
                classBtn={"center studentButton"}
                text={"Add Task"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                  height: "45px",
                  padding: "0 30px",
                  minWidth: "100px",
                  textWrap: "noWrap",
                }}
                click={() => setTaskOpen(true)}
              />
            )
          ) : (
            ""
          )}
        </div>
        {loading ? (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        ) : sessionData?.task && !loading ? (
          <>
            <h2 style={{ margin: "20px 0 10px" }}>- Task Description:</h2>
            <h3 style={{ marginBottom: "20px" }}>
              {sessionData?.task?.description}
            </h3>
            <h2 style={{ marginBottom: "10px" }}>- Task Images:</h2>
            {sessionData?.task?.images?.length ? (
              sessionData?.task?.images?.map((image) => (
                <img
                  alt="Logo"
                  src={`${BaseURL}/images/${image}`}
                  style={{ margin: "auto", height: "200px" }}
                />
              ))
            ) : (
              <h3 style={{ margin: "10px 0" }}>Task has no image..</h3>
            )}
          </>
        ) : (
          <h2 style={{ textAlign: "center", margin: "50px" }}>
            No Task Assigned Yet!!
          </h2>
        )}
      </div>
    </div>
  );
}

export default ClassSessionDetails;
