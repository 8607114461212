import { Button, Menu, MenuItem } from "@mui/material";
import "./Filter.css";
import React, { useContext, useEffect, useState } from "react";
import PrimaryButton from "../../Atoms/Buttons/Primary/PrimaryButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import TextFieldInput from "../../Atoms/Inputs/TextField/TextFieldInput";
import useFetch from "../../../Hooks/useFetch";
import {
  countriesURL,
  listCoursesURL,
  listSchoolsURL,
  listTeachersURL,
} from "../../../Hooks/URL";
import useFetchWhen from "../../../Hooks/useFetchWhen";
import { userContext } from "../../../Contexts/userContext";

function Filter({
  Gender = false,
  schools = false,
  teachers = false,
  courses = false,
  country = false,
  city = false,
  activated = false,
  terminated = false,
  setFilter,
}) {
  const { contextData, setContextData } = useContext(userContext);
  const [gender, setGender] = useState(null);
  const [school, setSchool] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const [course, setCourse] = useState(null);
  const [activatedValue, setActivated] = useState(null);
  const [terminatedValue, setTerminated] = useState(null);
  const [cityValue, setCity] = useState(null);
  const [countryValue, setCountry] = useState(42);

  const { data: countries } = useFetch(`${countriesURL}`);
  const { data: cities } = useFetchWhen(
    `${countriesURL}/${countryValue}/cities`,
    countryValue
  );

  const { data: schoolsList } = useFetchWhen(
    listSchoolsURL,
    contextData?.role == 1
  );
  const { data: teachersList } = useFetchWhen(
    contextData?.role == 1
      ? `${listTeachersURL}/${school}`
      : contextData?.role == 2
      ? listTeachersURL
      : "",
    (contextData?.role == 1 && school) || contextData?.role == 2
  );
  const { data: coursesList } = useFetch(listCoursesURL);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="filterButton"
      >
        <FilterListIcon className="filterIcon" /> Add Filter
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="filterArea">
          <p>Filters:</p>
          {country ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Country:</label>
              <TextFieldInput
                select
                newValue={countryValue}
                name={"country"}
                change={(e) => setCountry(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Country
                </MenuItem>
                {countries?.data?.map((countryItem) => (
                  <MenuItem value={countryItem?.id}>
                    {countryItem?.name}
                  </MenuItem>
                ))}
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {city ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>City:</label>
              <TextFieldInput
                select
                newValue={cityValue}
                name={"city"}
                change={(e) => setCity(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose City
                </MenuItem>
                {cities?.data?.map((cityItem) => (
                  <MenuItem value={cityItem?.id}>{cityItem?.name}</MenuItem>
                ))}
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {activated ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Activated:</label>
              <TextFieldInput
                select
                newValue={activatedValue}
                name={"activated"}
                change={(e) => setActivated(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Status
                </MenuItem>
                <MenuItem value={"1"}>Activated</MenuItem>
                <MenuItem value={"0"}>Not Activated</MenuItem>
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {terminated ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Terminated:</label>
              <TextFieldInput
                select
                newValue={terminatedValue}
                name={"terminated"}
                change={(e) => setTerminated(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Status
                </MenuItem>
                <MenuItem value={"1"}>Terminated</MenuItem>
                <MenuItem value={"0"}>Not Terminated</MenuItem>
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {Gender ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Gender:</label>
              <TextFieldInput
                select
                newValue={gender}
                name={"gender"}
                change={(e) => setGender(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Gender
                </MenuItem>
                <MenuItem value={"1"}>Male</MenuItem>
                <MenuItem value={"2"}>Female</MenuItem>
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {schools && contextData?.role == 1 ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>School:</label>
              <TextFieldInput
                select
                newValue={school}
                name={"school"}
                change={(e) => setSchool(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose School
                </MenuItem>
                {schoolsList?.data?.map((option) => (
                  <MenuItem value={option?.id}>{option?.name}</MenuItem>
                ))}
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {(teachers && contextData?.role == 1) ||
          (teachers && contextData?.role == 2) ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Teacher:</label>
              <TextFieldInput
                select
                newValue={teacher}
                name={"teacher"}
                change={(e) => setTeacher(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Teacher
                </MenuItem>
                {teachersList?.data?.map((option) => (
                  <MenuItem value={option?.id}>{option?.name}</MenuItem>
                ))}
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          {courses ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ marginRight: "10px" }}>Program:</label>
              <TextFieldInput
                select
                newValue={course}
                name={"course"}
                change={(e) => setCourse(e.target.value)}
                className={`CourseSelect filter`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="teacherSelect" disabled>
                  Choose Program
                </MenuItem>
                {coursesList?.data?.map((option) => (
                  <MenuItem value={option?.id}>{option?.title}</MenuItem>
                ))}
              </TextFieldInput>
            </div>
          ) : (
            ""
          )}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <PrimaryButton
              classBtn={"center addFilter"}
              text={"Apply filter"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                textWrap: "noWrap",
                margin: "0 5px",
              }}
              click={() => {
                setFilter(
                  `${school ? `&school_id=${school}` : ""}${
                    gender ? `&gender=${gender}` : ""
                  }${teacher ? `&teacher_id=${teacher}` : ""}${
                    course ? `&course_id=${course}` : ""
                  }${countryValue ? `&country_id=${countryValue}` : ""}${
                    cityValue ? `&city_id=${cityValue}` : ""
                  }${activatedValue ? `&activated=${activatedValue}` : ""}${
                    terminatedValue ? `&is_terminated=${terminatedValue}` : ""
                  }`
                );
              }}
            />
            <PrimaryButton
              classBtn={"center addFilter"}
              text={"Clear"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                textWrap: "noWrap",
                margin: "0 5px",
              }}
              click={() => {
                setGender(null);
                setSchool(null);
                setTeacher(null);
                setCourse(null);
                setCountry("");
                setCity(null);
                setTerminated(null);
                setActivated(null);
                setFilter("");
              }}
            />
          </div>
        </div>
      </Menu>
    </div>
  );
}

export default Filter;
