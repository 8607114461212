import React, { useContext, useEffect, useState } from "react";
import TeacherProfile from "../Templates/Home/Teachers/TeacherProfile/TeacherProfile";
import { schoolURL, teacherURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import Profile from "../Templates/Home/Profile/Profile";
import useFetchWhen from "../Hooks/useFetchWhen";

function ProfilePage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${contextData?.role == 2 ? schoolURL : teacherURL}/me`;
  const page = "profile";

  const [Open, setOpen] = useState(false);
  const [PassOpen, setPassOpen] = useState(false);

  const [profile, setProfile] = useState({});
  const { data, loading, error } = useFetchWhen(
    url,
    contextData?.role == 2 || contextData?.role == 3
  );

  useEffect(() => {
    if (!loading && !error && data) {
      setProfile(data.data);
      console.log(data?.data);
    }
  }, [data, loading, error]);

  return (
    <Profile
      Open={Open}
      setOpen={setOpen}
      PassOpen={PassOpen}
      setPassOpen={setPassOpen}
      profile={profile}
      setProfile={setProfile}
      url={url}
      loading={loading}
      role={contextData?.role}
    />
  );
}

export default ProfilePage;
