import "./Login.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { images } from "../../../Assets/assetsHelper";
import LoginForm from "../../../Components/Organisms/Forms/LoginForm/LoginForm";

function Login() {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="Login">
      <div className="leftLogin">
        <img alt="Logo" className="Company_Logo" src={images.Company_Logo} />
      </div>
      <div className="rightLogin">
        <h1 className="welcome">
          Welcome to Skill Code Dashboard,
          <br /> Log into your account
        </h1>
        <div className="container">
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

export default Login;
