import React, { useEffect, useState } from "react";
import "./AddClassForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import MenuItem from "@mui/material/MenuItem";
import useEdit from "../../../../Hooks/useEdit";
import useSave from "../../../../Hooks/useSave";
import {
  classesURL,
  listCoursesURL,
  listSchoolsURL,
  listTeachersURL,
} from "../../../../Hooks/URL";
import { Formik } from "formik";
import * as Yup from "yup";
import useFetch from "../../../../Hooks/useFetch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";

const AdminClassSchema = Yup.object().shape({
  school_id: Yup.string().required("School required !!"),
  course_id: Yup.string().required("Program required !!"),
  teacher_id: Yup.string().required("Teacher required !!"),
});

const SchoolClassSchema = Yup.object().shape({
  course_id: Yup.string().required("Program required !!"),
  teacher_id: Yup.string().required("Teacher required !!"),
});

const TeacherClassSchema = Yup.object().shape({
  course_id: Yup.string().required("Program required !!"),
});

const ClassEditSchema = Yup.object().shape({
  teacher_id: Yup.string().required("Teacher required !!"),
});

function AddClassForm({
  role,
  Edit = false,
  Class,
  url,
  setClass,
  setOpen,
  setClasses,
  page,
  search,
}) {
  const [newObject, setNewObject] = useState({});
  const [schoolID, setSchoolID] = useState(Class?.school?.id ?? "");
  const [date, setDate] = useState(dayjs(Class?.start_date) ?? "");
  const [goEdit, setGoEdit] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const { state, loading: editLoading } = useEdit(
    url,
    goEdit,
    setGoEdit,
    newObject,
    false,
    false,
    { function: setClass }
  );
  const { saveState, loading: addLoading } = useSave({
    url: classesURL,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setClasses,
      page: page,
      limit: 10,
      search: search,
      query: "&sort=~start_date",
    },
  });
  const { data: schoolsList } = useFetch(
    Edit ? "" : role == 1 ? listSchoolsURL : ""
  );
  const { data: teachersList } = useFetch(
    role == 1
      ? `${listTeachersURL}/${schoolID}`
      : role == 2
      ? listTeachersURL
      : "",
    null,
    null,
    role == 1 ? schoolID : "",
    null,
    role == 1 ? true : false
  );
  const { data: coursesList } = useFetch(Edit ? "" : listCoursesURL);

  useEffect(() => {
    if (state.dataRes.status == "success") {
      setOpen(false);
    }
  }, [state]);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        initialValues={
          role == 1
            ? {
                school_id: Edit ? Class?.school?.id ?? "" : "",
                course_id: Edit ? Class?.course?.id ?? "" : "",
                teacher_id: Edit ? Class?.teacher?.id ?? "" : "",
              }
            : role == 2
            ? {
                course_id: Edit ? Class?.course?.id ?? "" : "",
                teacher_id: Edit ? Class?.teacher?.id ?? "" : "",
              }
            : {
                course_id: Edit ? Class?.course?.id ?? "" : "",
              }
        }
        validationSchema={
          Edit
            ? ClassEditSchema
            : role == 1
            ? AdminClassSchema
            : role == 2
            ? SchoolClassSchema
            : TeacherClassSchema
        }
        onSubmit={(values) => {
          if (Class) {
            const prev =
              role == 1
                ? {
                    school_id: Class?.school?.id ?? "",
                    course_id: Class?.course?.id ?? "",
                    teacher_id: Class?.teacher?.id ?? "",
                  }
                : role == 2
                ? {
                    course_id: Class?.course?.id ?? "",
                    teacher_id: Class?.teacher?.id ?? "",
                  }
                : {
                    course_id: Class?.course?.id ?? "",
                  };
            const newObj = { ...values, start_date: date };
            setNewObject(
              Object.fromEntries(
                Object.entries({
                  ...prev,
                  ...newObj,
                }).filter(([key]) => prev[key] != newObj[key])
              )
            );
            setGoEdit(true);
          } else {
            setNewObject({ ...values, start_date: date });
            setGoSave(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassFormContainer">
              <br />
              {Edit || role != 1 ? (
                ""
              ) : (
                <>
                  <TextFieldInput
                    select
                    label="School"
                    newValue={values.school_id}
                    name={"school_id"}
                    change={(e) => {
                      handleChange(e);
                      setSchoolID(e.target.value);
                    }}
                    blur={handleBlur}
                    fullWidth
                    className={`Input`}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" className="teacherSelect" disabled>
                      Choose School
                    </MenuItem>
                    {schoolsList?.data?.map((schoolItem) => (
                      <MenuItem value={schoolItem?.id}>
                        {schoolItem?.name}
                      </MenuItem>
                    ))}
                  </TextFieldInput>
                  {errors.school_id &&
                    touched.school_id &&
                    errors.school_id && (
                      <p className="error">
                        {errors.school_id &&
                          touched.school_id &&
                          errors.school_id}
                      </p>
                    )}
                </>
              )}

              {Edit ? (
                ""
              ) : (
                <>
                  <TextFieldInput
                    select
                    label="Course"
                    newValue={values.course_id}
                    name={"course_id"}
                    change={handleChange}
                    blur={handleBlur}
                    fullWidth
                    className={`Input`}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" className="teacherSelect" disabled>
                      Choose Program
                    </MenuItem>
                    {coursesList?.data?.map((courseItem) => (
                      <MenuItem value={courseItem?.id}>
                        {courseItem?.title}
                      </MenuItem>
                    ))}
                  </TextFieldInput>
                  {errors.course_id &&
                    touched.course_id &&
                    errors.course_id && (
                      <p className="error">
                        {errors.course_id &&
                          touched.course_id &&
                          errors.course_id}
                      </p>
                    )}
                </>
              )}

              {role != 3 ? (
                <>
                  <TextFieldInput
                    select
                    label="Teacher"
                    newValue={values.teacher_id}
                    name={"teacher_id"}
                    change={handleChange}
                    blur={handleBlur}
                    fullWidth
                    className={`Input`}
                    variant="outlined"
                    SelectProps={{
                      displayEmpty: true,
                    }}
                  >
                    <MenuItem value="" className="teacherSelect" disabled>
                      {schoolID || role == 2
                        ? "Choose Teacher"
                        : "Please choose school first"}
                    </MenuItem>
                    {teachersList?.data?.map((teacherItem) => (
                      <MenuItem value={teacherItem?.id}>
                        {teacherItem?.name}
                      </MenuItem>
                    ))}
                  </TextFieldInput>
                  {errors.teacher_id &&
                    touched.teacher_id &&
                    errors.teacher_id && (
                      <p className="error">
                        {errors.teacher_id &&
                          touched.teacher_id &&
                          errors.teacher_id}
                      </p>
                    )}
                </>
              ) : (
                ""
              )}

              {Edit ? (
                ""
              ) : (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        className="DataInput"
                        name={"start_date"}
                        label="Start Date"
                        value={date}
                        onChange={(newValue) => setDate(newValue)}
                        fullWidth
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  {errors.start_date &&
                    touched.start_date &&
                    errors.start_date && (
                      <p className="error">
                        {errors.start_date &&
                          touched.start_date &&
                          errors.start_date}
                      </p>
                    )}
                </>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={Edit ? "Edit" : "Add"}
                loading={Edit ? editLoading : addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddClassForm;
