import { images } from "../../../../Assets/assetsHelper";
import "./StudentProfile.css";
import React, { useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddStudentForm from "../../../../Components/Organisms/Forms/AddStudnetForm/AddStudentForm";
import { BaseURL } from "../../../../Hooks/URL";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import Groups2Icon from "@mui/icons-material/Groups2";
import ClassIcon from "@mui/icons-material/Class";
import CourseCard from "../../../../Components/Molecules/Cards/CourseCard/CourseCard";
import { PulseLoader } from "react-spinners";

function StudentProfile({
  Open,
  setOpen,
  student,
  setStudent,
  url,
  setGo,
  loading,
  statistics,
  role,
  classesLoading,
  classes,
}) {
  return (
    <div className="mainContainer">
      <FormModal
        Open={Open}
        HandleClose={() => setOpen(false)}
        Form={
          <AddStudentForm
            Edit={true}
            Open={Open}
            setOpen={setOpen}
            student={student}
            url={url}
            setStudent={setStudent}
          />
        }
        Title={"Edit Student"}
      />
      <div className="StudentCont">
        {(role == 1 || role == 2) && !loading ? (
          <div className="EditDeleteHeader" style={{ flexWrap: "wrap", margin: "0 0 20px" }}>
            <PrimaryButton
              classBtn={"center editButton"}
              text={"Edit"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 30px",
                margin: "0 20px",
              }}
              click={() => setOpen(true)}
            />
            <DeleteForm
              setGo={setGo}
              title={"Are you sure to delete this student ?"}
            />
          </div>
        ) : (
          ""
        )}
        <div className="greyBox">
          <div className="detailsHeaderBox">
            <div className="detailsHeaderBoxInside">
              <img
                alt="photo"
                className="SchoolLogo"
                src={
                  student?.photo
                    ? `${BaseURL}/images/${student?.photo}`
                    : images.StudentPlace
                }
              />
              <div className="SchoolDetails">
                <h2 style={{ fontSize: "35px", margin: "0 0 10px 0" }}>
                  {student.name}
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="greyBoxWhite">
          <div className="greyNumberBox">
            <ClassIcon className="numbersIcon" />
            <div>
              <h4>CLASSES</h4>
              <h2>{statistics?.counting?.at(0)?.number_of_classes}</h2>
            </div>
          </div>
        </div>
        <div className="greyBoxVer">
          <h3>Parent Phone Number:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {student?.parent_phone}
          </p>
          <h3 style={{ marginTop: "20px" }}>Gender:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {student?.gender == 1 ? "Male" : "Female"}
          </p>
          <h3 style={{ marginTop: "20px" }}>Birthday:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {new Date(student?.birth_day).toLocaleDateString()}
          </p>
          <h3 style={{ marginTop: "20px" }}>Age:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>{student?.age}</p>
          <h3 style={{ marginTop: "20px" }}>National ID:</h3>
          <p style={{ maxWidth: "500px", marginTop: "10px" }}>
            {student?.national_id}
          </p>
          {role == 1 ? (
            <>
              <h3 style={{ margin: "20px 0" }}>School Hire From:</h3>
              {role == 1 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt="Logo"
                    style={{ width: "50px", margin: "0 5px" }}
                    src={
                      student?.school
                        ? `${BaseURL}/images/${student?.school?.logo}`
                        : images.Company_Logo
                    }
                  />
                  <p
                    style={{
                      maxWidth: "500px",
                      fontSize: "20px",
                    }}
                  >
                    {student?.school ? student?.school?.name : "Skill Code"}
                  </p>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </div>
        <div className="dashBox">
          <div className="dashClassesHeader">
            <h2>Classes:</h2>
          </div>
          {classes && !classesLoading ? (
            <div className="classesCont">
              {classes?.map((item) => (
                <CourseCard
                  class_id={item?.id}
                  class_name={item?.class_name}
                  course={item?.course_title}
                  teacher={item?.teacher_name}
                  school={item?.school_name}
                  terminated={item?.is_terminated}
                />
              ))}
            </div>
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default StudentProfile;
