import React, { useState } from "react";
import "./AddTaskForm.css";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";
import TextArea from "../../../Atoms/Inputs/TextArea/TextArea";
import useSave from "../../../../Hooks/useSave";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const taskSchema = Yup.object().shape({
  description: Yup.string().required("Description required !!"),
});

function AddTaskForm({ url, setOpen, setSessionData, FetchUrl }) {
  const [files, setFile] = useState([]);
  const [newObject, setNewObject] = useState({});
  const [goSave, setGoSave] = useState(false);
  const formData = new FormData();
  const { saveState, loading: addLoading } = useSave({
    url: url,
    goSave,
    setGoSave,
    newObject,
    isImage: true,
    setNewData: {
      function: setSessionData,
    },
    FetchUrl: FetchUrl,
  });

  const handleUploadClick = (e) => {
    if (files.length + [...e.target.files].length > 5)
      toast.error("5 images only allowed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    else setFile([...files, ...[...e.target.files]]);
  };

  if (saveState.dataRes.status == "success") {
    setOpen(false);
  }
  return (
    <div className="SmallFormWrapper">
      <Formik
        initialValues={{
          description: "",
        }}
        validationSchema={taskSchema}
        onSubmit={(values) => {
          for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i]);
          }
          formData.append("description", values.description);
          setNewObject(formData);
          setGoSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassTaskFormContainer">
              <div>
                <Typography style={{ fontWeight: "500", marginBottom: "10px" }}>
                  Task Images: {files.length} / 5
                </Typography>
                <Button
                  component="label"
                  variant="contained"
                  style={{ marginBottom: "20px" }}
                  startIcon={<CloudUploadIcon />}
                  className="ImageInputButton"
                >
                  Upload images
                  <input
                    multiple
                    accept="image/*"
                    className={"ImageInput"}
                    id="contained-button-file"
                    type="file"
                    onChange={handleUploadClick}
                    max={5}
                  />
                </Button>
              </div>
              <div>
                {files?.map((image) => (
                  <img
                    src={URL.createObjectURL(image)}
                    style={{
                      width: "120px",
                      maxHeight: "120px",
                      marginBottom: "10px",
                      marginRight: "5px",
                      cursor: "pointer",
                    }}
                    className="darkImage"
                    onClick={() => setFile(files.filter((img) => img != image))}
                  />
                ))}
              </div>
              <label className="aboutLabel">Task Details:</label>
              <TextArea
                classes="textAreaInputLarge"
                label={"About"}
                name={"description"}
                change={handleChange}
                blur={handleBlur}
                newValue={values.description}
              />
              {errors.description &&
                touched.description &&
                errors.description && (
                  <p className="errorTop">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </p>
                )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={"Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddTaskForm;
