import React, { useContext, useEffect, useState } from "react";
import { classesURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import ClassesDetails from "../Templates/Home/Classes/ClassesDetails/ClassesDetails";
import { useParams } from "react-router-dom";
import useSave from "../Hooks/useSave";
import { userContext } from "../Contexts/userContext";

function ClassesDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${classesURL}/${id}`;
  const studentUrl = `${classesURL}/${id}/students`;
  const page = "classes";

  const [Open, setOpen] = useState(false);
  const [StudentsOpen, setStudentsOpen] = useState(false);

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, page);

  const [Class, setClass] = useState({});
  const { data, loading, error } = useFetch(url);
  const [students, setStudents] = useState([]);
  const {
    data: studentsData,
    loading: studentsLoading,
    error: studentsError,
  } = useFetch(studentUrl);

  const [goSave, setGoSave] = useState(false);
  const { state, loading: TerminateLoading } = useSave({
    url: `${classesURL}/${id}/terminate`,
    goSave,
    setGoSave,
    setNewData: { function: setClass },
    FetchUrl: url,
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setClass(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({
      ...contextData,
      page: "classes",
      data: Class?.class_name,
      link: `classes/${Class?.id}`,
    });
  }, [Class]);

  useEffect(() => {
    if (!studentsLoading && !studentsError && studentsData) {
      setStudents(studentsData.data);
    }
  }, [studentsData, studentsLoading, studentsError]);

  return (
    <ClassesDetails
      role={contextData?.role}
      Open={Open}
      setOpen={setOpen}
      Class={Class}
      setClass={setClass}
      url={url}
      setGo={setGo}
      loading={loading}
      students={students}
      studentsLoading={studentsLoading}
      StudentsOpen={StudentsOpen}
      setStudentsOpen={setStudentsOpen}
      studentUrl={studentUrl}
      setGoSave={setGoSave}
      TerminateLoading={TerminateLoading}
      setStudents={setStudents}
    />
  );
}

export default ClassesDetailsPage;
