import React, { useEffect, useState } from "react";
import {
  classesURL,
  sessionsClassesURL,
} from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import ClassSessions from "../Templates/Home/Classes/ClassSessions/ClassSessions";

function ClassSessionsPage() {
  let { id } = useParams();
  const navigate = useNavigate();

  const ClassUrl = `${classesURL}/${id}`;
  const url = `${sessionsClassesURL(id)}`;

  const [sessionsOpen, setSessionsOpen] = useState(false);
  
  const [sessions, setSessions] = useState([]);
  const {
    data: Class,
    loading: classLoading,
    error: classError,
  } = useFetch(ClassUrl);
  const { data, loading, error } = useFetch(url);

  useEffect(() => {
    if (!loading && !error && data) {
      setSessions(data.data);
    }
  }, [data, loading, error]);

  return (
    <ClassSessions
      Class={Class?.data}
      sessions={sessions}
      loading={loading}
      sessionsOpen={sessionsOpen}
      setSessionsOpen={setSessionsOpen}
      url={url}
      setSessions={setSessions}
    />
  );
}

export default ClassSessionsPage;
