import React, { useContext, useEffect, useState } from "react";
import TeacherProfile from "../Templates/Home/Teachers/TeacherProfile/TeacherProfile";
import { teacherURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";

function TeacherProfilePage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${teacherURL}/${id}`;
  const page = "instructors";
  const statisticsUrl = `${teacherURL}/${id}/statistics`;

  const [Open, setOpen] = useState(false);

  const [teacher, setTeacher] = useState({});
  const { data, loading, error } = useFetch(url);

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, page);
  const [teacherNumbers, setTeacherNumbers] = useState({});
  const {
    data: TeacherNumbersData,
    loading: TeacherNumbersLoading,
    error: TeacherNumbersError,
  } = useFetch(statisticsUrl);

  const [goSave, setGoSave] = useState(false);
  const [activateClicked, setActivateClicked] = useState(false);
  const {
    saveState,
    loading: addLoading,
    newData,
  } = useSave({
    url: `${teacherURL}/${id}/resendActivation`,
    goSave,
    setGoSave,
  });

  useEffect(() => {
    if (!addLoading && saveState?.dataRes?.status) setActivateClicked(true);
  }, [addLoading, saveState]);

  useEffect(() => {
    if (!loading && !error && data) {
      setTeacher(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!TeacherNumbersError && !TeacherNumbersLoading && TeacherNumbersData) {
      setTeacherNumbers(TeacherNumbersData.data);
    }
  }, [TeacherNumbersData, TeacherNumbersLoading, TeacherNumbersError]);

  useEffect(() => {
    setContextData({
      ...contextData,
      page: "instructors",
      data: teacher?.name,
    });
  }, [teacher]);

  return (
    <TeacherProfile
      Open={Open}
      setOpen={setOpen}
      teacher={teacher}
      setTeacher={setTeacher}
      url={url}
      setGo={setGo}
      loading={loading}
      role={contextData?.role}
      addLoading={addLoading}
      setActivateClicked={setActivateClicked}
      activateClicked={activateClicked}
      setGoSave={setGoSave}
      statistics={teacherNumbers}
    />
  );
}

export default TeacherProfilePage;
