import "./ClassSessions.css";
import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddSessionForm from "../../../../Components/Organisms/Forms/AddSessionForm/AddSessionForm";
import SessionCard from "../../../../Components/Molecules/Cards/SessionCard/SessionCard";

function ClassSessions({
  Class,
  sessions,
  loading,
  sessionsOpen,
  setSessionsOpen,
  url,
  setSessions,
}) {
  return (
    <div className="mainContainer">
      <FormModal
        Open={sessionsOpen}
        HandleClose={() => setSessionsOpen(false)}
        Form={
          <AddSessionForm
            setOpen={setSessionsOpen}
            url={url}
            setSessions={setSessions}
          />
        }
        Title={"Add Session"}
        CustomStyle={"small"}
      />
      <div className="classDetailsCont cont">
        <div
          style={{
            marginBottom: "20px",
          }}
          className="header"
        >
          <h1>{Class?.class_name} Sessions</h1>
          {!Class?.is_terminated ? (
            <PrimaryButton
              classBtn={"center addButton"}
              text={"Add Session"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 30px",
                minWidth: "100px",
              }}
              click={() => setSessionsOpen(true)}
            />
          ) : (
            ""
          )}
        </div>
        {!loading ? (
          sessions?.length ? (
            <>
              <div className="studentsCont">
                {sessions?.map((session) => (
                  <SessionCard
                    sessionNo={session?.session_number}
                    session={session}
                    terminated={Class?.is_terminated}
                  />
                ))}
              </div>
            </>
          ) : (
            <h2 style={{ textAlign: "center", margin: "50px" }}>
              No Sessions Added Yet!!
            </h2>
          )
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ClassSessions;
