import { images } from "../../../Assets/assetsHelper";
import "./Profile.css";
import React, { useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import AddTeacherForm from "../../../Components/Organisms/Forms/AddTeacherForm/AddTeacherForm";
import { BaseURL } from "../../../Hooks/URL";
import Person3Icon from "@mui/icons-material/Person3";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteForm from "../../../Components/Molecules/DeleteForm/DeleteForm";
import Groups2Icon from "@mui/icons-material/Groups2";
import ClassIcon from "@mui/icons-material/Class";
import { useNavigate } from "react-router-dom";
import AddSchoolForm from "../../../Components/Organisms/Forms/AddSchoolForm/AddSchoolForm";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ChangePasswordForm from "../../../Components/Organisms/Forms/ChangePasswordForm/ChangePasswordForm";

function Profile({
  Open,
  setOpen,
  PassOpen,
  setPassOpen,
  url,
  loading,
  role,
  setProfile,
  profile,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <FormModal
        Open={Open}
        HandleClose={() => {
          setOpen(false);
        }}
        Form={
          role == 2 ? (
            <AddSchoolForm
              Edit={true}
              setOpen={setOpen}
              school={profile}
              url={url}
              setSchool={setProfile}
            />
          ) : role == 3 ? (
            <AddTeacherForm
              Edit={true}
              Open={Open}
              setOpen={setOpen}
              teacher={profile}
              url={url}
              setTeacher={setProfile}
            />
          ) : (
            ""
          )
        }
        Title={"Edit Profile"}
      />
      <FormModal
        Open={PassOpen}
        HandleClose={() => {
          setPassOpen(false);
        }}
        CustomStyle={"small"}
        Form={
          <ChangePasswordForm
            handleClose={() => {
              setPassOpen(false);
            }}
          />
        }
        Title={"Change Password"}
      />
      <div className="TeacherCont">
        <div className="EditDeleteHeader" style={{ flexWrap: "wrap", margin: "0 0 20px" }}>
          <PrimaryButton
            classBtn={"center editButton"}
            text={"Edit Profile"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "45px",
              padding: "0 30px ",
              margin: "0 20px 0 0",
            }}
            click={() => {
              setOpen(true);
            }}
          />
          {/* <PrimaryButton
                classBtn={"center editButton"}
                text={"Forget Password"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                  height: "45px",
                  padding: "0 30px ",
                  margin: "0 20px 0 0",
                }}
                click={() => {
                  setOpen(true);
                }}
              /> */}
          <PrimaryButton
            classBtn={"center editButton"}
            text={"Change Password"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "45px",
              padding: "0 30px",
            }}
            click={() => {
              setPassOpen(true);
            }}
          />
        </div>
        <div className="greyBox">
          <div className="detailsHeaderBoxProgram">
            <div className="detailsHeaderBoxInside">
              {role == 2 ? (
                profile?.logo ? (
                  <img
                    alt="Logo"
                    className="SchoolLogo"
                    src={`${BaseURL}/images/${profile?.logo}`}
                  />
                ) : (
                  <ApartmentIcon
                    style={{
                      fontSize: "150px",
                      color: "#06abc9",
                      marginRight: "20px",
                    }}
                  />
                )
              ) : role == 3 ? (
                <img
                  alt="photo"
                  className="SchoolLogo"
                  src={
                    profile?.photo
                      ? `${BaseURL}/images/${profile?.photo}`
                      : images.TeacherPlace
                  }
                />
              ) : (
                ""
              )}
              <div className="SchoolDetails">
                <h2 style={{ fontSize: "35px", margin: "0 0 10px 0" }}>
                  {profile?.name}
                </h2>
                {role == 2 ? (
                  <>
                    <a
                      href={profile?.site}
                      style={{ marginBottom: "20px" }}
                      target={"_blank"}
                    >
                      {profile?.site}
                    </a>
                    <div style={{ display: "flex" }}>
                      {profile?.facebook ? (
                        <a target={"_blank"} href={profile?.facebook}>
                          <FacebookIcon
                            className="SchoolSocialIcon"
                            style={{ color: "#3b5998" }}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {profile?.instagram ? (
                        <a target={"_blank"} href={profile?.instagram}>
                          <InstagramIcon
                            className="SchoolSocialIcon"
                            style={{ color: "#C13584" }}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {profile?.whatsapp ? (
                        <a target={"_blank"} href={profile?.whatsapp}>
                          <WhatsAppIcon
                            className="SchoolSocialIcon"
                            style={{ color: "#25D366" }}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

          </div>
        </div>
        {role == 2 ? (
          <div className="greyBoxVer">
            <h3 style={{}}>About the school:</h3>
            <p style={{ marginTop: "10px" }}>{profile?.about}</p>
            <h3 style={{ marginTop: "20px" }}>Email:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {profile?.email}
            </p>
            <h3 style={{ marginTop: "20px" }}>Phone Number:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {profile?.phone}
            </p>
            <h3 style={{ marginTop: "20px" }}>Whatsapp Number:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {`+${profile?.whatsapp?.split("=")?.at(1)}`}
            </p>
            <h3 style={{ marginTop: "20px" }}>Country / City / Street:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {profile?.country} / {profile?.city} / {profile?.street}
            </p>
            <h3 style={{ marginTop: "20px" }}>Joining Date:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {new Date(profile?.join_date).toLocaleString()}
            </p>
          </div>
        ) : (
          <div className="greyBoxVer">
            <h3>Email:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {profile?.email}
            </p>
            <h3 style={{ marginTop: "20px" }}>Phone Number:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {profile?.phone}
            </p>
            <h3 style={{ marginTop: "20px" }}>Gender</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {profile?.gender == 1 ? "Male" : "Female"}
            </p>
            <h3 style={{ marginTop: "20px" }}>National ID</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {profile?.national_id}
            </p>
            {role == 1 ? (
              <>
                <h3 style={{ margin: "20px 0" }}>School Hire From</h3>
                {role == 1 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      alt="Logo"
                      style={{ width: "50px", margin: "0 5px" }}
                      src={
                        profile?.school
                          ? `${BaseURL}/images/${profile?.school?.logo}`
                          : images.Company_Logo
                      }
                    />
                    <p
                      style={{
                        maxWidth: "500px",
                        fontSize: "20px",
                      }}
                    >
                      {profile?.school ? profile?.school?.name : "Skill Code"}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
