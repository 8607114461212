import React, { useEffect, useState } from "react";
import { reportsClassesURL, reportsURL, studentURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import { useLocation, useParams } from "react-router-dom";
import ClassFeedback from "../Templates/Home/Classes/ClassFeedback/ClassFeedback";
import useEdit from "../Hooks/useEdit";
import useSave from "../Hooks/useSave";
import useFetchWhen from "../Hooks/useFetchWhen";

function ClassFeedbackPage() {
  let { id, studentId } = useParams();
  const location = useLocation();

  const url = `${reportsClassesURL(id)}/attributes`;
  const studentUrl = `${studentURL}/${studentId}`;
  const reportUrl = `${reportsClassesURL(id)}/students/${studentId}`;

  const { data, loading, error } = useFetch(url);
  const {
    data: studentData,
    loading: studentLoading,
    error: studentError,
  } = useFetch(studentUrl);
  const {
    data: reportData,
    loading: reportLoading,
    error: reportError,
  } = useFetchWhen(reportUrl, location.state.edit);

  const [newObject, setNewObject] = useState({});
  const [attributes, setAttributes] = useState([]);

  const [goEdit, setGoEdit] = useState(false);
  const { loading: editLoading } = useEdit(
    `${reportsURL(id)}/${reportData?.data?.id}`,
    goEdit,
    setGoEdit,
    newObject,
    `/classes/${id}/report`
  );

  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: `${reportsClassesURL(id)}/students/${studentId}`,
    goSave,
    setGoSave,
    newObject,
    navigateAfter: `/classes/${id}/report`,
  });

  useEffect(() => {
    if (!loading && !error && data) {
      setAttributes(data.data);
    }
  }, [data, loading, error]);

  return (
    <ClassFeedback
      studentData={studentData?.data}
      reportData={reportData?.data}
      reportLoading={reportLoading}
      attributes={attributes}
      studentLoading={studentLoading}
      loading={loading}
      setGoSave={setGoSave}
      classId={id}
      studentId={studentId}
      addLoading={addLoading}
      setNewObject={setNewObject}
      Edit={location.state.edit}
      editLoading={editLoading}
      setGoEdit={setGoEdit}
    />
  );
}

export default ClassFeedbackPage;
