import "./SideBar.css";
import React, { useContext, useState } from "react";
import SideListItem from "../../../Molecules/SideListItem/SideListItem";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { userContext } from "../../../../Contexts/userContext";

function SideBar({ setShowMenu }) {
  const { contextData, setContextData } = useContext(userContext);

  return (
    <div className="SideBar" id="SideBar">
      <ul className="TabList">
        <SideListItem
          name={"home"}
          Pathname={"/"}
          title="Dashboard"
          icon={<DashboardRoundedIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
        />
        {contextData?.role == 1 ? (
          <>
            <SideListItem
              onClick={() => setShowMenu(false)}
              name={"schools"}
              Pathname={"/schools"}
              title="Schools"
              icon={<LocationCityRoundedIcon className="ListIcon" />}
              setShowMenu={setShowMenu}
              arrowRotate={contextData?.data && contextData?.page == "schools"}
            />
            {contextData?.data && contextData?.page == "schools" ? (
              <SideListItem
                name={"schools"}
                Pathname={"/schools"}
                title={contextData?.data}
                customClass={"nested"}
                setShowMenu={setShowMenu}
                nested
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {contextData?.role == 1 || contextData?.role == 2 ? (
          <>
            <SideListItem
              name={"instructors"}
              Pathname={"/instructors"}
              title="Instructors"
              icon={<PeopleAltRoundedIcon className="ListIcon" />}
              setShowMenu={setShowMenu}
              arrowRotate={
                contextData?.data && contextData?.page == "instructors"
              }
            />
            {contextData?.data && contextData?.page == "instructors" ? (
              <SideListItem
                name={"instructors"}
                Pathname={"/instructors"}
                title={contextData?.data}
                customClass={"nested"}
                setShowMenu={setShowMenu}
                nested
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <SideListItem
          name={"students"}
          Pathname={"/students"}
          title="Students"
          icon={<SchoolRoundedIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
          arrowRotate={contextData?.data && contextData?.page == "students"}
        />
        {contextData?.data && contextData?.page == "students" ? (
          <SideListItem
            name={"students"}
            Pathname={"/students"}
            title={contextData?.data}
            customClass={"nested"}
            setShowMenu={setShowMenu}
            nested
          />
        ) : (
          ""
        )}
        <SideListItem
          name={"programs"}
          Pathname={"/programs"}
          title="Programs"
          icon={<AutoStoriesRoundedIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
          arrowRotate={contextData?.data && contextData?.page == "programs"}
        />
        {contextData?.data && contextData?.page == "programs" ? (
          <SideListItem
            name={"programs"}
            Pathname={"/programs"}
            title={contextData?.data}
            customClass={"nested"}
            setShowMenu={setShowMenu}
            nested
          />
        ) : (
          ""
        )}
        <SideListItem
          name={"classes"}
          Pathname={"/classes"}
          title="Classes"
          icon={<AccountBalanceRoundedIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
          arrowRotate={contextData?.data && contextData?.page == "classes"}
        />
        {contextData?.data && contextData?.page == "classes" ? (
          <SideListItem
            name={"classes"}
            Pathname={"/classes"}
            title={contextData?.data}
            customClass={"nested"}
            setShowMenu={setShowMenu}
            nested
            link={contextData?.link}
          />
        ) : (
          ""
        )}
        {contextData?.role != 1 ? (
          <SideListItem
            name={"account"}
            Pathname={"/account"}
            title="My account"
            icon={<AccountCircleIcon className="ListIcon" />}
            setShowMenu={setShowMenu}
          />
        ) : (
          ""
        )}
      </ul>
    </div>
  );
}

export default SideBar;
