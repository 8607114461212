import "./NavBar.css";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../../../Assets/assetsHelper";
import MenuIcon from "@mui/icons-material/Menu";
import { BaseURL, logoutURL } from "../../../../Hooks/URL";
import useLogout from "../../../../Hooks/useLogout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { userContext } from "../../../../Contexts/userContext";

function NavBar({ setShowMenu }) {
  const navigate = useNavigate();
  const { contextData, setContextData } = useContext(userContext);

  const [goSave, setGoSave] = useState(false);
  const { saveState } = useLogout({
    url: logoutURL,
    goSave,
    setGoSave,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="nav">
      <Link className="NavLogo" to="/">
        <img alt="Logo" className="NavLogo" src={images.Company_Logo_Hor} />
      </Link>
      <div className="rightNav">
        <div className="sidebarIcon" onClick={() => setShowMenu(true)}>
          <span className={"menuIcon"}>
            <MenuIcon
              sx={{ fontSize: "27px", cursor: "pointer", marginTop: "5px" }}
            />
          </span>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {contextData?.user?.photo ? (
            <img
              alt="Logo"
              src={`${BaseURL}/images/${contextData?.user?.photo}`}
              width={50}
              style={{ margin: "0 10px" }}
              className="desktop"
            />
          ) : contextData?.role == 1 ? (
            <img
              alt="Logo"
              src={images.Company_Logo}
              width={50}
              style={{ margin: "0 10px" }}
              className="desktop"
            />
          ) : (
            <AccountCircleIcon
              style={{
                fontSize: "50px",
                color: "#06abc9",
                marginRight: "10px",
              }}
              className="desktop"
            />
          )}
          <span
            className="desktop"
            style={{
              marginRight: "10px",
            }}
          >
            {contextData?.role == 1
              ? "Administrator"
              : contextData?.user?.name ?? "Username"}
          </span>
          {contextData?.role != 1 ? (
            <PersonIcon
              style={{
                fontSize: "40px",
                color: "#06abc9",
                marginLeft: "10px",
                cursor: "pointer",
              }}
              className="navIcon"
              onClick={() => {
                navigate("/account");
              }}
            />
          ) : (
            ""
          )}
          <LogoutIcon
            style={{
              fontSize: "40px",
              color: "#06abc9",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            className="navIcon"
            onClick={() => setGoSave(true)}
          />
          {/* <AccountCircleIcon
            style={{
              fontSize: "50px",
              color: "#06abc9",
              marginLeft: "30px",
              cursor: "pointer",
            }}
            onClick={handleClick}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          />
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 2,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 21,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              style={{ fontSize: "20px", minWidth: "200px" }}
              onClick={() => {
                handleClose();
                navigate("/account");
              }}
            >
              My account
            </MenuItem>
            <MenuItem
              style={{ fontSize: "20px", minWidth: "200px" }}
              onClick={() => setGoSave(true)}
            >
              Logout
            </MenuItem>
          </Menu> */}
        </div>
      </div>
    </div>
  );
}

export default NavBar;
