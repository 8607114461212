import { useNavigate, useParams } from "react-router-dom";
import "./SessionCard.css";
import React, { useState } from "react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";

function SessionCard({ sessionNo, session, terminated }) {
  let { id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <div className="SessionCard">
        <div>
          <h2>Session {sessionNo}</h2>
          <h4>date: {session?.date_time?.split("T")?.at(0)}</h4>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
          className="Buttons"
        >
          <PrimaryButton
            classBtn={"center studentButton"}
            text={"See more"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              padding: "0 15px",
              minWidth: "100px",
              textWrap: "noWrap",
              margin: "0 5px",
            }}
            click={() => {
              navigate(`/classes/${id}/sessions/${session?.id}`, {
                state: { sessionNo: sessionNo },
              });
            }}
          />
        </div>
      </div>
    </>
  );
}

export default SessionCard;
