import React, { useContext, useEffect, useState } from "react";
import "./AddTeacherForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";
import useEdit from "../../../../Hooks/useEdit";
import useSave from "../../../../Hooks/useSave";
import { BaseURL, listSchoolsURL, teacherURL } from "../../../../Hooks/URL";
import { Formik } from "formik";
import * as Yup from "yup";
import useFetch from "../../../../Hooks/useFetch";
import phone from "phone";
import { PhoneInput } from "react-international-phone";
import useFetchWhen from "../../../../Hooks/useFetchWhen";
import { userContext } from "../../../../Contexts/userContext";
import { images } from "../../../../Assets/assetsHelper";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CloseIcon from "@mui/icons-material/Close";

const AdminTeacherSchema = Yup.object().shape({
  name: Yup.string().required("Name required !!"),
  email: Yup.string().email("Invalid email !!").required("Email required !!"),
  gender: Yup.string().required("Gender required !!"),
  national_id: Yup.string().required("National ID required !!"),
  school: Yup.string().required("School required !!"),
});

const TeacherSchema = Yup.object().shape({
  name: Yup.string().required("Name required !!"),
  email: Yup.string().email("Invalid email !!").required("Email required !!"),
  gender: Yup.string().required("Gender required !!"),
  national_id: Yup.string().required("National ID required !!"),
});

function AddTeacherForm({
  Edit = false,
  teacher,
  url,
  setTeacher,
  setOpen,
  setTeachers,
  page,
  search,
}) {
  const { contextData, setContextData } = useContext(userContext);
  const [file, setFile] = useState("");
  const [newObject, setNewObject] = useState({});
  const [Phone, setPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(null);
  const [PhoneTouched, setPhoneTouched] = useState(false);
  const [goEdit, setGoEdit] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const { state, loading: editLoading } = useEdit(
    url,
    goEdit,
    setGoEdit,
    newObject,
    false,
    true,
    { function: setTeacher }
  );
  const { saveState, loading: addLoading } = useSave({
    url: teacherURL,
    goSave,
    setGoSave,
    newObject,
    isImage: true,
    setNewData: {
      function: setTeachers,
      page: page,
      limit: 10,
      search: search,
    },
  });
  const {
    data: schoolsList,
    loading: schoolsLoading,
    error: schoolsError,
  } = useFetchWhen(listSchoolsURL, contextData?.role == 1);

  const handleUploadClick = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!phone(Phone)?.isValid) setPhoneError("Valid Phone Number Required!!");
    else setPhoneError("");
  }, [Phone]);

  useEffect(() => {
    if (teacher) {
      setPhone(teacher?.phone);
    }
  }, [teacher]);

  useEffect(() => {
    if (state.dataRes.status == "success") {
      setOpen(false);
    }
  }, [state]);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  return (
    <div className="SmallFormWrapper">
      <Formik
        initialValues={
          contextData?.role == 1
            ? {
                name: Edit ? teacher?.name ?? "" : "",
                email: Edit ? teacher?.email ?? "" : "",
                gender: Edit ? teacher?.gender ?? "" : "",
                national_id: Edit ? teacher?.national_id ?? "" : "",
                school: Edit ? teacher?.school?.id ?? -1 : "",
              }
            : {
                name: Edit ? teacher?.name ?? "" : "",
                email: Edit ? teacher?.email ?? "" : "",
                gender: Edit ? teacher?.gender ?? "" : "",
                national_id: Edit ? teacher?.national_id ?? "" : "",
              }
        }
        validationSchema={
          contextData?.role == 1 ? AdminTeacherSchema : TeacherSchema
        }
        onSubmit={(values) => {
          if (!PhoneError)
            if (teacher) {
              const prev =
                contextData?.role == 1
                  ? {
                      name: teacher?.name ?? "",
                      phone: teacher?.phone ?? "",
                      email: teacher?.email ?? "",
                      gender: teacher?.gender ?? "",
                      national_id: teacher?.national_id ?? "",
                      school: teacher?.school?.id ?? -1,
                    }
                  : {
                      name: teacher?.name ?? "",
                      phone: teacher?.phone ?? "",
                      email: teacher?.email ?? "",
                      gender: teacher?.gender ?? "",
                      national_id: teacher?.national_id ?? "",
                    };
              if (file)
                setNewObject({
                  ...Object.fromEntries(
                    Object.entries({
                      ...prev,
                      ...values,
                      phone: Phone,
                    }).filter(([key]) => prev[key] != values[key])
                  ),
                  photo: file,
                });
              else
                setNewObject(
                  Object.fromEntries(
                    Object.entries({
                      ...prev,
                      ...values,
                      phone: Phone,
                    }).filter(([key]) => prev[key] != values[key])
                  )
                );
              setGoEdit(true);
            } else {
              setNewObject({ photo: file, phone: Phone, ...values });
              setGoSave(true);
            }
          else {
            setPhoneTouched(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="FormContainer">
              <div className="LeftForm">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <Typography
                      style={{ fontWeight: "500", marginBottom: "10px" }}
                    >
                      Instructor Photo:
                    </Typography>
                    {(file && file !== "null") ||
                    (teacher?.photo && file !== "null") ? (
                      <Button
                        component="label"
                        variant="contained"
                        color="secondary"
                        startIcon={<CloseIcon style={{ fontSize: "20" }} />}
                        className="ImageInputButton RemovePhoto"
                        onClick={() => {
                          setFile("null");
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <img
                      src={
                        file && file !== "null"
                          ? URL.createObjectURL(file)
                          : teacher?.photo && file !== "null"
                          ? `${BaseURL}/images/${teacher?.photo}`
                          : images.upload
                      }
                      style={{
                        width: "120px",
                        height: "120px",
                        cursor: "pointer",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        setFile("null");
                      }}
                    />
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon style={{ fontSize: "25" }} />}
                      className="ImageInputButton uploadButton"
                    >
                      <input
                        accept="image/*"
                        className={"ImageInput"}
                        id="contained-button-file"
                        type="file"
                        onChange={handleUploadClick}
                      />
                    </Button>
                  </div>
                </div>
                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter Instructor Name"}
                  className="Input"
                  label={"Instructor Name"}
                  name={"name"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.name}
                />
                {errors.name && touched.name && errors.name && (
                  <p className="error">
                    {errors.name && touched.name && errors.name}
                  </p>
                )}

                {!Edit ? (
                  <>
                    <TextFieldInput
                      fullWidth
                      type="email"
                      placeholder={"Enter Instructor Email"}
                      className="Input"
                      label={"Instructor Email"}
                      name={"email"}
                      change={handleChange}
                      blur={handleBlur}
                      newValue={values.email}
                    />
                    {errors.email && touched.email && errors.email && (
                      <p className="error">
                        {errors.email && touched.email && errors.email}
                      </p>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="RightForm">
                <div style={{ position: "relative", marginBottom: "20px" }}>
                  <label className="labelPhone">Phone Number*</label>
                  <PhoneInput
                    label={"Enter Instructor Phone Number"}
                    className="textfield Input phoneInput"
                    value={Phone}
                    defaultCountry="eg"
                    onChange={(e) => {
                      setPhone(e);
                    }}
                  />
                  {PhoneError && PhoneTouched ? (
                    <p className="error">{PhoneError}</p>
                  ) : (
                    ""
                  )}
                </div>

                <TextFieldInput
                  select
                  label="Gender"
                  newValue={values.gender}
                  name={"gender"}
                  change={handleChange}
                  blur={handleBlur}
                  fullWidth
                  className={`CourseSelect Input`}
                  variant="outlined"
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="teacherSelect" disabled>
                    Choose Instructor Gender
                  </MenuItem>
                  <MenuItem value="1">Male</MenuItem>
                  <MenuItem value="2">Female</MenuItem>
                </TextFieldInput>
                {errors.gender && touched.gender && errors.gender && (
                  <p className="error">
                    {errors.gender && touched.gender && errors.gender}
                  </p>
                )}

                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter Instructor National ID"}
                  className="Input"
                  label={"National ID"}
                  name={"national_id"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.national_id}
                />
                {errors.national_id &&
                  touched.national_id &&
                  errors.national_id && (
                    <p className="error">
                      {errors.national_id &&
                        touched.national_id &&
                        errors.national_id}
                    </p>
                  )}

                {contextData?.role == 1 ? (
                  <>
                    <TextFieldInput
                      select
                      label="School"
                      newValue={values.school}
                      name={"school"}
                      change={handleChange}
                      blur={handleBlur}
                      fullWidth
                      className={`CourseSelect Input`}
                      variant="outlined"
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" className="teacherSelect" disabled>
                        Choose School
                      </MenuItem>
                      <MenuItem value={-1}>Skill Code</MenuItem>
                      {schoolsList?.data?.map((schoolItem) => (
                        <MenuItem value={schoolItem?.id}>
                          {schoolItem?.name}
                        </MenuItem>
                      ))}
                    </TextFieldInput>
                    {errors.school && touched.school && errors.school && (
                      <p className="error">
                        {errors.school && touched.school && errors.school}
                      </p>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={Edit ? "Edit" : "Add"}
                loading={Edit ? editLoading : addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddTeacherForm;
