import { useNavigate, useParams } from "react-router-dom";
import "./ReportCard.css";
import React, { useState } from "react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../../Assets/assetsHelper";
import useDelete from "../../../../Hooks/useDelete";
import { BaseURL, classesURL, reportsURL } from "../../../../Hooks/URL";

function ReportCard({ student, classId, setStudents, studentUrl }) {
  let { id } = useParams();
  const navigate = useNavigate();
  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(
    `${reportsURL(id)}/${student?.report_id}`,
    go,
    null,
    { function: setStudents },
    studentUrl
  );
  return (
    <>
      <div className="ReportCard">
        <div>
          <img
            alt="Logo"
            className="StudentPicCard"
            src={
              student?.photo
                ? `${BaseURL}/images/${student?.photo}`
                : images.StudentPlace
            }
            style={{ margin: "auto" }}
          />
          <h2>{student?.name} Report</h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
          className="Buttons"
        >
          {student?.report_id ? (
            <>
              <PrimaryButton
                classBtn={"center studentButton"}
                text={"Show Report"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                  height: "40px",
                  padding: "0 15px",
                  minWidth: "100px",
                  textWrap: "noWrap",
                  margin: "0 5px",
                }}
                click={() =>
                  navigate(
                    `/classes/${classId}/report/${student?.student_id}`,
                    {
                      state: {
                        edit: true,
                      },
                    }
                  )
                }
              />
              <PrimaryButton
                classBtn={"center studentButton deleteBtn"}
                text={"Delete Report"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "#D11A2A",
                  height: "40px",
                  padding: "0 15px",
                  minWidth: "100px",
                  textWrap: "noWrap",
                  margin: "10px 0 0 0",
                }}
                click={() => setGo(true)}
              />
            </>
          ) : (
            <PrimaryButton
              classBtn={"center studentButton"}
              text={"Add Report"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "40px",
                padding: "0 15px",
                minWidth: "100px",
                textWrap: "noWrap",
                margin: "0 5px",
              }}
              click={() =>
                navigate(`/classes/${classId}/report/${student?.student_id}`, {
                  state: {
                    edit: false,
                  },
                })
              }
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ReportCard;
