import { useNavigate, useParams } from "react-router-dom";
import "./StudentCard.css";
import React, { useState } from "react";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { images } from "../../../../Assets/assetsHelper";
import useDelete from "../../../../Hooks/useDelete";
import { BaseURL, classesURL } from "../../../../Hooks/URL";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function StudentCard({ student, setStudents, is_terminated }) {
  const navigate = useNavigate();
  let { id } = useParams();
  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(
    `${classesURL}/${id}/students`,
    go,
    null,
    { function: setStudents },
    null,
    {
      students: [student?.id],
    }
  );
  return (
    <>
      <div className="StudentCard">
        <img
          alt="Logo"
          className="StudentPicCard"
          src={
            student?.photo
              ? `${BaseURL}/images/${student?.photo}`
              : images.StudentPlace
          }
          style={{ margin: "auto" }}
        />
        <h2>{student?.name}</h2>
        <div className="Buttons">
          <PrimaryButton
            classBtn={"center studentButton"}
            text={"see more"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "40px",
              padding: "0 15px",
              minWidth: "100px",
              textWrap: "noWrap",
              margin: "0 5px",
            }}
            click={() => navigate(`/students/${student?.id}`)}
          />
          {is_terminated ? (
            ""
          ) : (
            <PrimaryButton
              classBtn={"center studentButton deleteBtn"}
              text={"Remove"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "#D11A2A",
                height: "40px",
                padding: "0 15px",
                minWidth: "100px",
                textWrap: "noWrap",
                margin: "0 5px",
              }}
              click={() => setGo(true)}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default StudentCard;
