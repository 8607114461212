import { images } from "../../../../Assets/assetsHelper";
import "./CourseDetails.css";
import React, { useContext, useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddCourseForm from "../../../../Components/Organisms/Forms/AddCourseForm/AddCourseForm";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { userContext } from "../../../../Contexts/userContext";
import TextArea from "../../../../Components/Atoms/Inputs/TextArea/TextArea";

function CourseDetails({
  Open,
  setOpen,
  course,
  setCourse,
  url,
  setGo,
  loading,
}) {
  const { contextData, setContextData } = useContext(userContext);
  return (
    <div className="mainContainer">
      <div className="StudentCont">
        <FormModal
          Open={Open}
          HandleClose={() => setOpen(false)}
          Form={
            <AddCourseForm
              Edit={true}
              setOpen={setOpen}
              course={course}
              url={url}
              setCourse={setCourse}
            />
          }
          Title={"Edit Program"}
        />
        {contextData?.role == 1 && !loading ? (
          <div className="EditDeleteHeader" style={{ flexWrap: "wrap", margin: "0 0 20px" }}>
            <PrimaryButton
              classBtn={"center editButton"}
              text={"Edit"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 30px",
                margin: "0 20px",
              }}
              click={() => setOpen(true)}
            />
            <DeleteForm
              setGo={setGo}
              title={"Are you sure to delete this course ?"}
            />
          </div>
        ) : (
          ""
        )}
        <div className="greyBox">
          <div className="detailsHeaderBoxProgram">

            <div className="detailsHeaderBoxInside">
              <div className="SchoolDetails" style={{ width: "100%" }}>
                <h2 style={{ fontSize: "35px", margin: "0 0 10px 0" }}>
                  {course.title}
                </h2>
                <h3 style={{ margin: "20px 0 10px" }}>About the program:</h3>
                <TextArea
                  classes="textAreaInput displayOnly"
                  label={"Description"}
                  name={"description"}
                  newValue={course.description}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <h2 style={{ fontSize: "28px", margin: "10px" }}>
          Feedback attributes:
        </h2>
        <div className="greyBoxWhite">
          {course.attributes &&
            course.attributes.map((a) => (
              <div className="greyNumberBox">
                <div>
                  <h4>{a.attribute}</h4>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default CourseDetails;
