import React, { useEffect, useState } from "react";
import { sessionsClassesURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import { useNavigate, useParams } from "react-router-dom";
import ClassSessionDetails from "../Templates/Home/Classes/ClassSessionDetails/ClassSessionDetails";
import useDelete from "../Hooks/useDelete";

function ClassSessionDetailsPage() {
  let { id, session_id } = useParams();
  const navigate = useNavigate();

  const url = `${sessionsClassesURL(id)}/${session_id}`;
  const TasksUrl = `${sessionsClassesURL(id)}/${session_id}/tasks`;

  const [taskOpen, setTaskOpen] = useState(false);

  const [sessionData, setSessionData] = useState({});
  const { data, loading, error } = useFetch(url);

  const [go, setGo] = useState(false);
  const { deleteRes, loading: DeleteLoading } = useDelete(
    `${sessionsClassesURL(id)}/${session_id}`,
    go,
    `classes/${id}/sessions`
  );

  const [goTask, setGoTask] = useState(false);
  const { deleteRes: DeleteResTask, loading: TaskDeleteLoading } = useDelete(
    TasksUrl,
    goTask,
    "",
    { function: setSessionData },
    url
  );

  useEffect(() => {
    if (!loading && !error && data) {
      setSessionData(data.data);
    }
  }, [data, loading, error]);

  return (
    <ClassSessionDetails
      sessionData={sessionData}
      loading={loading}
      taskOpen={taskOpen}
      setTaskOpen={setTaskOpen}
      url={TasksUrl}
      FetchUrl={url}
      setGo={setGo}
      setGoTask={setGoTask}
      DeleteLoading={DeleteLoading}
      TaskDeleteLoading={TaskDeleteLoading}
      setSessionData={setSessionData}
    />
  );
}

export default ClassSessionDetailsPage;
