import React, { useContext, useEffect, useState } from "react";
import "./AddStudentForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Typography } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import useSave from "../../../../Hooks/useSave";
import useEdit from "../../../../Hooks/useEdit";
import { BaseURL, listSchoolsURL, studentURL } from "../../../../Hooks/URL";
import { Formik } from "formik";
import * as Yup from "yup";
import useFetch from "../../../../Hooks/useFetch";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import phone from "phone";
import { PhoneInput } from "react-international-phone";
import { userContext } from "../../../../Contexts/userContext";
import useFetchWhen from "../../../../Hooks/useFetchWhen";
import { images } from "../../../../Assets/assetsHelper";
import CloseIcon from "@mui/icons-material/Close";

const AdminStudentSchema = Yup.object().shape({
  name: Yup.string().required("Name required !!"),
  gender: Yup.string().required("Gender required !!"),
  national_id: Yup.string().required("National ID required !!"),
  school: Yup.string().required("School required !!"),
});

const StudentSchema = Yup.object().shape({
  name: Yup.string().required("Name required !!"),
  gender: Yup.string().required("Gender required !!"),
  national_id: Yup.string().required("National ID required !!"),
});

function AddStudentForm({
  Edit = false,
  student,
  url,
  setStudent,
  setOpen,
  Open,
  setStudents,
  page,
  search,
}) {
  const { contextData, setContextData } = useContext(userContext);
  const [file, setFile] = useState("");
  const [date, setDate] = useState(dayjs(student?.birth_day) ?? "");
  const [newObject, setNewObject] = useState({});
  const [Phone, setPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(null);
  const [PhoneTouched, setPhoneTouched] = useState(false);
  const [goEdit, setGoEdit] = useState(false);
  const [goSave, setGoSave] = useState(false);
  const {
    state,
    fetchNewData,
    loading: editLoading,
  } = useEdit(url, goEdit, setGoEdit, newObject, false, true, {
    function: setStudent,
  });
  const { saveState, loading: addLoading } = useSave({
    url: studentURL,
    goSave,
    setGoSave,
    newObject,
    isImage: true,
    setNewData: {
      function: setStudents,
      page: page,
      limit: 10,
      search: search,
    },
  });
  const {
    data: schoolsList,
    loading: schoolsLoading,
    error: schoolsError,
  } = useFetchWhen(listSchoolsURL, contextData?.role == 1);

  const handleUploadClick = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (!phone(Phone)?.isValid) setPhoneError("Valid Phone Number Required!!");
    else setPhoneError("");
  }, [Phone]);

  useEffect(() => {
    if (student) {
      setPhone(student?.parent_phone);
    }
  }, [student]);

  useEffect(() => {
    if (state.dataRes.status == "success") {
      setOpen(false);
    }
  }, [state]);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  return (
    <div className="SmallFormWrapper">
      <Formik
        initialValues={
          contextData?.role == 1
            ? {
                name: Edit ? student?.name ?? "" : "",
                gender: Edit ? student?.gender ?? "" : "",
                national_id: Edit ? student?.national_id ?? "" : "",
                school: Edit ? student?.school?.id ?? "" : "",
              }
            : {
                name: Edit ? student?.name ?? "" : "",
                gender: Edit ? student?.gender ?? "" : "",
                national_id: Edit ? student?.national_id ?? "" : "",
              }
        }
        validationSchema={
          contextData?.role == 1 ? AdminStudentSchema : StudentSchema
        }
        onSubmit={(values) => {
          if (!PhoneError)
            if (student) {
              const prev =
                contextData?.role == 1
                  ? {
                      name: student?.name ?? "",
                      birth_day: student?.birth_day ?? "",
                      gender: student?.gender ?? "",
                      national_id: student?.national_id ?? "",
                      school: student?.school?.id ?? "",
                    }
                  : {
                      name: student?.name ?? "",
                      birth_day: student?.birth_day ?? "",
                      gender: student?.gender ?? "",
                      national_id: student?.national_id ?? "",
                    };
              const newObj = { ...values, birth_day: date };
              if (file)
                setNewObject({
                  ...Object.fromEntries(
                    Object.entries({
                      ...prev,
                      ...values,
                      parent_phone: Phone,
                    }).filter(([key]) => prev[key] != values[key])
                  ),
                  photo: file,
                });
              else
                setNewObject(
                  Object.fromEntries(
                    Object.entries({
                      ...prev,
                      ...values,
                      parent_phone: Phone,
                    }).filter(([key]) => prev[key] != values[key])
                  )
                );
              setGoEdit(true);
            } else {
              setNewObject({
                ...values,
                birth_day: date,
                photo: file,
                parent_phone: Phone,
              });
              setGoSave(true);
            }
          else {
            setPhoneTouched(true);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="FormContainer">
              <div className="LeftForm">
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <Typography
                      style={{ fontWeight: "500", marginBottom: "10px" }}
                    >
                      Student Photo:
                    </Typography>
                    {(file && file !== "null") ||
                    (student?.photo && file !== "null") ? (
                      <Button
                        component="label"
                        variant="contained"
                        color="secondary"
                        startIcon={<CloseIcon style={{ fontSize: "20" }} />}
                        className="ImageInputButton RemovePhoto"
                        onClick={() => {
                          setFile("null");
                        }}
                      />
                    ) : (
                      ""
                    )}
                    <img
                      src={
                        file && file !== "null"
                          ? URL.createObjectURL(file)
                          : student?.photo && file !== "null"
                          ? `${BaseURL}/images/${student?.photo}`
                          : images.upload
                      }
                      style={{
                        width: "120px",
                        height: "120px",
                        cursor: "pointer",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      onClick={() => {
                        setFile("null");
                      }}
                    />
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon style={{ fontSize: "25" }} />}
                      className="ImageInputButton uploadButton"
                    >
                      <input
                        accept="image/*"
                        className={"ImageInput"}
                        id="contained-button-file"
                        type="file"
                        onChange={handleUploadClick}
                      />
                    </Button>
                  </div>
                </div>
                <TextFieldInput
                  fullWidth
                  type="text"
                  placeholder={"Enter Student Name"}
                  className="Input"
                  label={"Student Name"}
                  name={"name"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.name}
                />
                {errors.name && touched.name && errors.name && (
                  <p className="error">
                    {errors.name && touched.name && errors.name}
                  </p>
                )}

                <TextFieldInput
                  select
                  label="Gender"
                  newValue={values.gender}
                  name={"gender"}
                  change={handleChange}
                  blur={handleBlur}
                  style={{ margin: "10px 0" }}
                  fullWidth
                  className={`StudentSelect ${
                    values.gender == "" ? "StudentSelect-placeholder" : ""
                  }`}
                  variant="outlined"
                  SelectProps={{
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="StudentSelect" disabled>
                    Choose Student Gender
                  </MenuItem>
                  <MenuItem value="1">Male</MenuItem>
                  <MenuItem value="2">Female</MenuItem>
                </TextFieldInput>
                {errors.gender && touched.gender && errors.gender && (
                  <p className="error">
                    {errors.gender && touched.gender && errors.gender}
                  </p>
                )}
              </div>
              <div className="RightForm">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      className="Input full"
                      name={"birth_day"}
                      label="Birth Date"
                      value={date}
                      onChange={(newValue) => setDate(newValue)}
                      fullWidth
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {errors.birth_day && touched.birth_day && errors.birth_day && (
                  <p className="error">
                    {errors.birth_day && touched.birth_day && errors.birth_day}
                  </p>
                )}

                <div style={{ position: "relative", marginBottom: "0px" }}>
                  <label className="labelPhone">Parent Phone Number*</label>
                  <PhoneInput
                    label={"Enter Parent Phone Number"}
                    className="textfield Input phoneInput"
                    value={Phone}
                    defaultCountry="eg"
                    onChange={(e) => {
                      setPhone(e);
                    }}
                  />
                  {PhoneError && PhoneTouched ? (
                    <p className="error">{PhoneError}</p>
                  ) : (
                    ""
                  )}
                </div>

                <TextFieldInput
                  fullWidth
                  type="text"
                  label={"Student National ID"}
                  placeholder={"Enter Student National ID"}
                  className="Input"
                  name={"national_id"}
                  change={handleChange}
                  blur={handleBlur}
                  newValue={values.national_id}
                />
                {errors.national_id &&
                  touched.national_id &&
                  errors.national_id && (
                    <p className="error">
                      {errors.national_id &&
                        touched.national_id &&
                        errors.national_id}
                    </p>
                  )}

                {contextData?.role == 1 ? (
                  <>
                    <TextFieldInput
                      select
                      label="School"
                      newValue={values.school}
                      name={"school"}
                      change={handleChange}
                      blur={handleBlur}
                      fullWidth
                      className={`CourseSelect Input`}
                      variant="outlined"
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem value="" className="teacherSelect" disabled>
                        Choose School
                      </MenuItem>
                      {schoolsList?.data?.map((schoolItem) => (
                        <MenuItem value={schoolItem?.id}>
                          {schoolItem?.name}
                        </MenuItem>
                      ))}
                    </TextFieldInput>
                    {errors.school && touched.school && errors.school && (
                      <p className="error">
                        {errors.school && touched.school && errors.school}
                      </p>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={Edit ? "Edit" : "Add"}
                loading={Edit ? editLoading : addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddStudentForm;
