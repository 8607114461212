import "./Dashboard.css";
import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import { images } from "../../../Assets/assetsHelper";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import { useNavigate } from "react-router";
import Groups2Icon from "@mui/icons-material/Groups2";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import SchoolIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import { PulseLoader } from "react-spinners";
import { ResponsiveChartContainer, PieArcPlot } from "@mui/x-charts";
import CourseCard from "../../../Components/Molecules/Cards/CourseCard/CourseCard";
import { slice } from "lodash";

function Dashboard({
  contextData,
  statistics,
  loading,
  classes,
  classesLoading,
}) {
  const navigate = useNavigate();
  const date = new Date();
  return (
    <div className="mainContainer">
      <div className="dashBox">
        <div>
          <h1>
            Welcome to{" "}
            {contextData?.role == 1 ? "Skill Code" : contextData?.user?.name}{" "}
            Dashboard
          </h1>
          {/* <PrimaryButton
            classBtn={"center addButton"}
            text={"Track Your classes"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "50px",
              padding: "0 30px",
              minWidth: "100px",
              textWrap: "noWrap",
              marginTop: "20px",
            }}
            click={() => navigate("/classes")}
          /> */}
        </div>
        {/* <img src={images.DashGirl} alt="" /> */}
        <h2>
          School Year {date.getYear() + 1900 - 1} - {date.getYear() + 1900}
        </h2>
      </div>
      <div className="dashBoxWhite">
        {contextData?.role == 1 ? (
          <div className="NumberBox" onClick={() => navigate("/schools")}>
            <HolidayVillageIcon className="numbersIcon" />
            <div>
              <h4>SCHOOLS</h4>
              <h2>{statistics?.counting?.at(0)?.number_of_schools}</h2>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="NumberBox" onClick={() => navigate("/instructors")}>
          <SchoolIcon className="numbersIcon" />
          <div>
            <h4>INSTRUCTORS</h4>
            <h2>{statistics?.counting?.at(0)?.number_of_teachers}</h2>
          </div>
        </div>
        <div className="NumberBox" onClick={() => navigate("/students")}>
          <Groups2Icon className="numbersIcon" />
          <div>
            <h4>STUDENTS</h4>
            <h2>{statistics?.counting?.at(0)?.number_of_students}</h2>
          </div>
        </div>
        {/* <div className="NumberBox"
          onClick={() => navigate("/programs")}>
          <AutoStoriesIcon className="numbersIcon" />
          <div>
            <h4>COURSES</h4>
            <h2>{statistics?.counting?.at(0)?.number_of_courses}</h2>
          </div>
        </div> */}
        <div className="NumberBox" onClick={() => navigate("/classes")}>
          <ClassIcon className="numbersIcon" />
          <div>
            <h4>CLASSES</h4>
            <h2>{statistics?.counting?.at(0)?.number_of_classes}</h2>
          </div>
        </div>
      </div>
      <div className="chartsCont">
        <div className="dashBoxBlock w75">
          <h2>Number of Enrolled Students per Month</h2>
          {statistics ? (
            statistics?.studentsEnrollmentOverYear?.length ? (
              <BarChart
                dataset={
                  statistics
                    ? statistics?.studentsEnrollmentOverYear?.map((item) => ({
                      month: item?.month,
                      count: item?.count,
                    }))
                    : []
                }
                xAxis={[{ scaleType: "band", dataKey: "month" }]}
                series={[
                  { dataKey: "count", label: "No. of students / Month" },
                ]}
              />
            ) : (
              <h2 style={{ textAlign: "center", margin: "auto" }}>
                No Data Yet
              </h2>
            )
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </div>
        <div className="dashBoxBlock w25">
          <h2>Number of Classes per Courses</h2>
          {statistics ? (
            statistics?.numberOfClassesPerCourse?.length ? (
              <PieChart
                series={[
                  {
                    data: statistics
                      ? statistics?.numberOfClassesPerCourse?.slice(0, 3)?.map((item) => ({
                        id: item?.course_id,
                        label: item?.course_title,
                        value: item?.count,
                      }))
                      : [],
                    innerRadius: 25,
                    paddingAngle: 5,
                    cornerRadius: 5,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: { vertical: "top", horizontal: "center" },
                    padding: 0,
                    itemGap: 10,
                  },
                }}
                margin={{ left: 30, right: 30, top: 50 }}
                label={"No of students in classes"}
              />
            ) : (
              <h2 style={{ textAlign: "center", margin: "auto" }}>
                No Data Yet
              </h2>
            )
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </div>
        <div className="dashBoxBlock w50">
          <h2>Males & Females in Each Age Group</h2>
          {statistics ? (
            statistics?.agesWithGender?.length ? (
              <BarChart
                dataset={
                  statistics
                    ? statistics?.agesWithGender?.map((item) => ({
                      male: item?.male,
                      female: item?.female,
                      age: item?.age,
                    }))
                    : {}
                }
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "age",
                  },
                ]}
                series={[
                  { dataKey: "male", label: "Male" },
                  { dataKey: "female", label: "Female" },
                ]}
              />
            ) : (
              <h2 style={{ textAlign: "center", margin: "auto" }}>
                No Data Yet
              </h2>
            )
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </div>
        <div className="dashBoxBlock w50">
          <h2>Average Grade per Course</h2>
          {statistics ? (
            statistics?.coursesWithAverageGrade?.length ? (
              <BarChart
                dataset={
                  statistics?.coursesWithAverageGrade?.length
                    ? statistics?.coursesWithAverageGrade?.map((item) => ({
                      grade: item?.average_grade,
                      course: item?.course_title,
                    }))
                    : []
                }
                xAxis={[{ scaleType: "band", dataKey: "course" }]}
                series={[{ dataKey: "grade", label: "Average Grade / Course" }]}
              />
            ) : (
              <h2 style={{ textAlign: "center", margin: "auto" }}>
                No Data Yet
              </h2>
            )
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </div>
      </div>
      <div className="dashBox">
        <div className="dashClassesHeader">
          <h2>Latest Classes</h2>
          <PrimaryButton
            classBtn={"center addButton"}
            text={"See All classes"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "45px",
              padding: "0 30px",
              minWidth: "100px",
              textWrap: "noWrap",
            }}
            click={() => navigate("/classes")}
          />
        </div>
        {classes && !classesLoading ? (
          <div className="classesCont">
            {classes?.map((item) => (
              <CourseCard
                class_id={item?.id}
                class_name={item?.class_name}
                course={item?.course_title}
                teacher={item?.teacher_name}
                school={item?.school_name}
                terminated={item?.is_terminated}
              />
            ))}
          </div>
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
