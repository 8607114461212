import React, { useEffect, useState } from "react";
import "./AddStudentsToClassForm.css";
import TextFieldInput from "../../../Atoms/Inputs/TextField/TextFieldInput";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import { Autocomplete } from "@mui/material";
import useSave from "../../../../Hooks/useSave";
import { classesURL } from "../../../../Hooks/URL";
import { Formik } from "formik";
import useFetch from "../../../../Hooks/useFetch";

function AddStudentsToClassForm({
  Edit = false,
  Class,
  url,
  setOpen,
  setStudents,
  page,
  search,
}) {
  const [newObject, setNewObject] = useState({});
  const [stud, setstud] = useState([]);
  const [error, setError] = useState("");
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: url,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setStudents,
    },
  });
  const {
    data: studentsList,
    loading: studentsLoading,
    error: studentsError,
  } = useFetch(`${classesURL}/${Class?.id}/students/toEnroll?enrolled=false`);

  useEffect(() => {
    if (saveState.dataRes.status == "success") {
      setOpen(false);
    }
  }, [saveState]);

  useEffect(() => {
    if (stud.length) {
      setError("");
    }
  }, [stud]);

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        initialValues={{
          students: [],
        }}
        onSubmit={(values) => {
          if (stud.length) {
            setNewObject({ students: stud?.map((item) => item?.id) });
            setGoSave(true);
          } else {
            setError("Add at least one student !!");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassFormContainer">
              <br />

              <Autocomplete
                multiple
                options={studentsList?.data ?? []}
                getOptionLabel={(option) => option?.name}
                filterSelectedOptions
                name={"students"}
                className="chooseStudents"
                value={stud}
                onChange={(e, newValue) => setstud(newValue)}
                renderInput={(params) => (
                  <TextFieldInput
                    {...params}
                    label="Add Student"
                    placeholder="Students"
                    className={`Input`}
                    styles={{ width: "100%" }}
                    name={"students"}
                    Required={false}
                  />
                )}
              />
              {error && <p className="error">{error}</p>}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={Edit ? "Edit" : "Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddStudentsToClassForm;
