import { images } from "../../../../Assets/assetsHelper";
import "./SchoolProfile.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddSchoolForm from "../../../../Components/Organisms/Forms/AddSchoolForm/AddSchoolForm";
import { PulseLoader } from "react-spinners";
import { BaseURL } from "../../../../Hooks/URL";
import ApartmentIcon from "@mui/icons-material/Apartment";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { useNavigate } from "react-router";
import Groups2Icon from "@mui/icons-material/Groups2";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import SchoolIcon from "@mui/icons-material/School";
import ClassIcon from "@mui/icons-material/Class";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import CourseCard from "../../../../Components/Molecules/Cards/CourseCard/CourseCard";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

function SchoolProfile({
  Open,
  setOpen,
  school,
  setSchool,
  url,
  setGo,
  loading,
  statistics,
  role,
  classes,
  classesLoading,
  setGoSave,
  goSave,
  addLoading,
  setActivateClicked,
  activateClicked,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <FormModal
        Open={Open}
        HandleClose={() => {
          setOpen(false);
        }}
        Form={
          <AddSchoolForm
            Edit={true}
            setOpen={setOpen}
            school={school}
            url={url}
            setSchool={setSchool}
          />
        }
        Title={"Edit School"}
      />

      {school && !loading ? (
        <div className="SchoolCont">
          {school && !loading ? (
            <div className="EditDeleteHeader" style={{ flexWrap: "wrap", margin: "0 0 20px" }}>
              {school?.activated || activateClicked ? (
                ""
              ) : (
                <PrimaryButton
                  classBtn={"center editButton"}
                  text={"Resend Verification"}
                  loading={addLoading}
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "5px",
                    color: "var(--text-secondary)",
                    backgroundColor: "var(--primary)",
                    height: "45px",
                    padding: "0 20px",
                    margin: "0 20px",
                    width: "max-content",
                  }}
                  click={() => {
                    setGoSave(true);
                  }}
                />
              )}
              <PrimaryButton
                classBtn={"center editButton"}
                text={"Edit"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                  height: "45px",
                  padding: "0 30px",
                  margin: "0 20px 0 0 ",
                }}
                click={() => {
                  setOpen(true);
                }}
              />
              <DeleteForm
                setGo={setGo}
                title={"Are you sure to delete this school ?"}
              />
            </div>) : ""}
          <div className="greyBox">
            <div className="detailsHeaderBox">
              <div className="detailsHeaderBoxInside">
                {school?.logo ? (
                  <img
                    alt="Logo"
                    className="SchoolLogo"
                    src={`${BaseURL}/images/${school?.logo}`}
                  />
                ) : (
                  <ApartmentIcon
                    style={{
                      fontSize: "150px",
                      color: "#06abc9",
                      marginRight: "20px",
                    }}
                  />
                )}
                <div className="SchoolDetails">
                  <h2 style={{ fontSize: "30px", margin: "0 0 10px 0" }}>
                    {school.name}
                  </h2>
                  <a
                    href={school?.site}
                    style={{ marginBottom: "20px" }}
                    target={"_blank"}
                  >
                    {school?.site}
                  </a>
                  <div style={{ display: "flex" }}>
                    {school?.facebook ? (
                      <a target={"_blank"} href={school?.facebook}>
                        <FacebookIcon
                          className="SchoolSocialIcon"
                          style={{ color: "#3b5998" }}
                        />
                      </a>
                    ) : (
                      ""
                    )}
                    {school?.instagram ? (
                      <a target={"_blank"} href={school?.instagram}>
                        <InstagramIcon
                          className="SchoolSocialIcon"
                          style={{ color: "#C13584" }}
                        />
                      </a>
                    ) : (
                      ""
                    )}
                    {school?.whatsapp ? (
                      <a target={"_blank"} href={school?.whatsapp}>
                        <WhatsAppIcon
                          className="SchoolSocialIcon"
                          style={{ color: "#25D366" }}
                        />
                      </a>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="greyBoxWhite">
            <div className="greyNumberBox">
              <SchoolIcon className="numbersIcon" />
              <div>
                <h4>INSTRUCTORS</h4>
                <h2>{statistics?.counting?.at(0)?.number_of_teachers}</h2>
              </div>
            </div>
            <div className="greyNumberBox">
              <Groups2Icon className="numbersIcon" />
              <div>
                <h4>STUDENTS</h4>
                <h2>{statistics?.counting?.at(0)?.number_of_students}</h2>
              </div>
            </div>
            <div className="greyNumberBox">
              <ClassIcon className="numbersIcon" />
              <div>
                <h4>CLASSES</h4>
                <h2>{statistics?.counting?.at(0)?.number_of_classes}</h2>
              </div>
            </div>
          </div>
          <div className="greyBoxVer">
            <h3 style={{}}>About the school:</h3>
            <p style={{ marginTop: "10px" }}>{school?.about}</p>
            <h3 style={{ marginTop: "20px" }}>Email:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {school?.email}
            </p>
            <h3 style={{ marginTop: "20px" }}>Phone Number:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {school?.phone}
            </p>
            <h3 style={{ marginTop: "20px" }}>Country / City / Street:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {school?.country} / {school?.city} / {school?.street}
            </p>
            <h3
              style={{
                marginTop: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Is Activated:{" "}
              {school?.activated ? (
                <DoneIcon className="activatedTick" />
              ) : (
                <CloseIcon className="NonActivatedTick" />
              )}
            </h3>
            <h3 style={{ marginTop: "20px" }}>Joining Date:</h3>
            <p style={{ maxWidth: "500px", marginTop: "10px" }}>
              {new Date(school?.join_date).toLocaleString()}
            </p>
          </div>
          <div className="chartsCont">
            <div className="dashBoxBlock w75">
              <h2>Number of Enrolled Students per Month</h2>
              {statistics ? (
                statistics?.studentsEnrollmentOverYear?.length ? (
                  <BarChart
                    dataset={
                      statistics
                        ? statistics?.studentsEnrollmentOverYear?.map(
                          (item) => ({
                            month: item?.month,
                            count: item?.count,
                          })
                        )
                        : []
                    }
                    xAxis={[{ scaleType: "band", dataKey: "month" }]}
                    series={[
                      { dataKey: "count", label: "No. of students / Month" },
                    ]}
                  />
                ) : (
                  <h2 style={{ textAlign: "center", margin: "auto" }}>
                    No Data Yet
                  </h2>
                )
              ) : (
                <div className="loadingBox">
                  <PulseLoader color="#06abc9" />
                </div>
              )}
            </div>
            <div className="dashBoxBlock w25">
              <h2>Number of Classes per Courses</h2>
              {statistics ? (
                statistics?.numberOfClassesPerCourse?.length ? (
                  <PieChart
                    series={[
                      {
                        data: statistics
                          ? statistics?.numberOfClassesPerCourse?.map(
                            (item) => ({
                              id: item?.course_id,
                              label: item?.course_title,
                              value: item?.count,
                            })
                          )
                          : [],
                        innerRadius: 25,
                        paddingAngle: 5,
                        cornerRadius: 5,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    slotProps={{
                      legend: {
                        direction: "row",
                        position: { vertical: "top", horizontal: "center" },
                        padding: 0,
                        itemGap: 10,
                      },
                    }}
                    margin={{ left: 30, right: 30, top: 50 }}
                    label={"No of students in classes"}
                  />
                ) : (
                  <h2 style={{ textAlign: "center", margin: "auto" }}>
                    No Data Yet
                  </h2>
                )
              ) : (
                <div className="loadingBox">
                  <PulseLoader color="#06abc9" />
                </div>
              )}
            </div>
            <div className="dashBoxBlock w50">
              <h2>Males & Females in Each Age Group</h2>
              {statistics ? (
                statistics?.agesWithGender?.length ? (
                  <BarChart
                    dataset={
                      statistics
                        ? statistics?.agesWithGender?.map((item) => ({
                          male: item?.male,
                          female: item?.female,
                          age: item?.age,
                        }))
                        : {}
                    }
                    xAxis={[
                      {
                        scaleType: "band",
                        dataKey: "age",
                      },
                    ]}
                    series={[
                      { dataKey: "male", label: "Male" },
                      { dataKey: "female", label: "Female" },
                    ]}
                  />
                ) : (
                  <h2 style={{ textAlign: "center", margin: "auto" }}>
                    No Data Yet
                  </h2>
                )
              ) : (
                <div className="loadingBox">
                  <PulseLoader color="#06abc9" />
                </div>
              )}
            </div>
            <div className="dashBoxBlock w50">
              <h2>Average Grade per Course</h2>
              {statistics ? (
                statistics?.coursesWithAverageGrade?.length ? (
                  <BarChart
                    dataset={
                      statistics?.coursesWithAverageGrade?.length
                        ? statistics?.coursesWithAverageGrade?.map((item) => ({
                          grade: item?.average_grade,
                          course: item?.course_title,
                        }))
                        : []
                    }
                    xAxis={[{ scaleType: "band", dataKey: "course" }]}
                    series={[
                      { dataKey: "grade", label: "Average Grade / Course" },
                    ]}
                  />
                ) : (
                  <h2 style={{ textAlign: "center", margin: "auto" }}>
                    No Data Yet
                  </h2>
                )
              ) : (
                <div className="loadingBox">
                  <PulseLoader color="#06abc9" />
                </div>
              )}
            </div>
          </div>
          <div className="dashBox">
            <div className="dashClassesHeader">
              <h2>Latest Classes</h2>
              <PrimaryButton
                classBtn={"center addButton"}
                text={"See All classes"}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                  height: "45px",
                  padding: "0 30px",
                  minWidth: "100px",
                  textWrap: "noWrap",
                }}
                click={() => navigate("/classes")}
              />
            </div>
            {classes && !classesLoading ? (
              <div
                className="classesCont"
                style={{ display: classes?.length ? "inherit" : "flex" }}
              >
                {classes?.length ? (
                  classes?.map((item) => (
                    <CourseCard
                      class_id={item?.id}
                      class_name={item?.class_name}
                      course={item?.course_title}
                      teacher={item?.teacher_name}
                      school={item?.school_name}
                      terminated={item?.is_terminated}
                    />
                  ))
                ) : (
                  <h2
                    style={{
                      textAlign: "center",
                      margin: "50px auto",
                    }}
                  >
                    No Classes Created Yet
                  </h2>
                )}
              </div>
            ) : (
              <div className="loadingBox">
                <PulseLoader color="#06abc9" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="loadingBox">
          <PulseLoader color="#06abc9" />
        </div>
      )}
    </div>
  );
}

export default SchoolProfile;
