import "./Courses.css";
import React, { useContext, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import AddCourseForm from "../../../Components/Organisms/Forms/AddCourseForm/AddCourseForm";
import { PulseLoader } from "react-spinners";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import CourseDetailsCard from "../../../Components/Molecules/Cards/CourseDetailsCard/CourseDetailsCard";
import { userContext } from "../../../Contexts/userContext";
import { Pagination } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06abc9",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  { id: "title", label: "Program Name", maxWidth: 20 },
  {
    id: "description",
    label: "Description",
    minWidth: 150,
  },
];

function Courses({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  searchData,
  search,
  setSearchData,
  setSearch,
  courses,
  navigate,
  handleChangePage,
  Open,
  loading,
  setOpen,
  data,
  error,
  pageCount,
  setCourses,
}) {
  const { contextData, setContextData } = useContext(userContext);
  return (
    <div className="mainContainer">
      <div className="cont">
        <FormModal
          Open={Open}
          HandleClose={() => setOpen(false)}
          Form={
            <AddCourseForm
              Edit={false}
              setOpen={setOpen}
              setCourses={setCourses}
              search={search}
              page={page}
            />
          }
          Title={"Add New Program"}
        />
        <div className="header">
          <TextFieldInput
            searchIcon={true}
            Required={false}
            type="text"
            placeholder={"Search"}
            className="SearchBar"
            name={"search"}
            change={(e) => setSearchData(e.target.value)}
            newValue={searchData}
            searchAction={() => {
              if (search != searchData) {
                setSearch(searchData);
              }
            }}
            keyDown={(e) => {
              if (e.keyCode === 13)
                if (search != searchData) {
                  setSearch(searchData);
                }
            }}
          />
          {contextData?.role == 1 ? (
            <PrimaryButton
              classBtn={"center addButton"}
              text={"Add Program"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 30px",
                minWidth: "100px",
                textWrap: "noWrap",
              }}
              click={() => setOpen(true)}
            />
          ) : (
            ""
          )}
        </div>
        <Paper
          className="tableCont"
          sx={{ width: "fit-content", minWidth: "100%" }}
        >
          {courses && !loading ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {courses.map((course) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={course.code}
                          onClick={() => {
                            navigate(`/programs/${course.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = course[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{
                                  maxWidth: "100px",
                                  overflow: "hidden",
                                  overflowX: "clip",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {column.id === "logo" && value ? (
                                  <img
                                    alt="Logo"
                                    width={"50px"}
                                    height={"50px"}
                                    src={value}
                                  />
                                ) : (
                                  <>
                                    {column.id === "attributes" && value ? (
                                      <>
                                        {value.map((a) => (
                                          <p>{a.attribute}</p>
                                        ))}
                                      </>
                                    ) : (
                                      <>{value}</>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!courses?.length ? (
                  <h3 style={{ textAlign: "center", margin: "50px" }}>
                    No Programs Added Yet!!
                  </h3>
                ) : (
                  ""
                )}
              </TableContainer>
              <Pagination
                className="paginationCount paginationCountMargin"
                count={pageCount}
                page={page + 1}
                onChange={handleChangePage}
                size="large"
              />
              {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </>
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default Courses;
