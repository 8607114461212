import "./Classes.css";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { images } from "../../../Assets/assetsHelper";
import { Link, useNavigate } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
// import SearchBar from "material-ui-search-bar";
import { PulseLoader } from "react-spinners";
import AddClassForm from "../../../Components/Organisms/Forms/AddClassForm/AddClassForm";
import CourseCard from "../../../Components/Molecules/Cards/CourseCard/CourseCard";
import { TablePagination } from "@mui/material";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import Pagination from "@mui/material/Pagination";
import Filter from "../../../Components/Molecules/Filter/Filter";

function Classes({
  role,
  page,
  searchData,
  search,
  setSearchData,
  setSearch,
  classes,
  navigate,
  handleChangePage,
  Open,
  loading,
  setOpen,
  data,
  error,
  pageCount,
  setClasses,
  setFilter,
}) {
  return (
    <div className="mainContainer">
      <div className="cont">
        <FormModal
          Open={Open}
          HandleClose={() => setOpen(false)}
          Form={
            <AddClassForm
              Edit={false}
              setOpen={setOpen}
              role={role}
              setClasses={setClasses}
              page={page}
              search={search}
            />
          }
          Title={"Add New Class"}
        />
        <div className="header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextFieldInput
              searchIcon={true}
              Required={false}
              type="text"
              placeholder={"Search"}
              className="SearchBar"
              name={"search"}
              change={(e) => setSearchData(e.target.value)}
              newValue={searchData}
              searchAction={() => {
                if (search != searchData) {
                  setSearch(searchData);
                }
              }}
              keyDown={(e) => {
                if (e.keyCode === 13)
                  if (search != searchData) {
                    setSearch(searchData);
                  }
              }}
            />
            <Filter schools teachers courses terminated setFilter={setFilter} />
          </div>
          {role == 1 || role == 2 ? (
            <PrimaryButton
              classBtn={"center addButton"}
              text={"Add Class"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 30px",
                minWidth: "100px",
                textWrap: "noWrap",
              }}
              click={() => setOpen(true)}
            />
          ) : (
            ""
          )}
        </div>
        {classes && !loading ? (
          <>
            <div className="classesCont">
              {classes?.map((item) => (
                <CourseCard
                  class_id={item?.id}
                  class_name={item?.class_name}
                  course={item?.course_title}
                  teacher={item?.teacher_name}
                  school={item?.school_name}
                  terminated={item?.is_terminated}
                />
              ))}
            </div>
            {!classes?.length ? (
              <h3 style={{ textAlign: "center", margin: "50px" }}>
                No Classes Created Yet!!
              </h3>
            ) : (
              ""
            )}
            <Pagination
              className="paginationCount"
              count={pageCount}
              page={page + 1}
              onChange={handleChangePage}
              size="large"
            />
          </>
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Classes;
