import { images } from "../../../../Assets/assetsHelper";
import "./ClassesDetails.css";
import React, { useState } from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";
import AddClassForm from "../../../../Components/Organisms/Forms/AddClassForm/AddClassForm";
import { PulseLoader } from "react-spinners";
import StudentCard from "../../../../Components/Molecules/Cards/StudentCard/StudentCard";
import AddStudentsToClassForm from "../../../../Components/Organisms/Forms/AddStudentsToClassForm/AddStudentsToClassForm";
import { TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

function ClassesDetails({
  role,
  Open,
  setOpen,
  Class,
  setClass,
  url,
  setGo,
  loading,
  students,
  studentsLoading,
  StudentsOpen,
  setStudentsOpen,
  studentUrl,
  setGoSave,
  TerminateLoading,
  setStudents,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      {Class && !loading ? (<div
        className="EditDeleteHeaderClassDetails" style={{ flexWrap: "wrap", margin: "0 0 20px" }}
      >
        <PrimaryButton
          classBtn={"center classButton"}
          text={Class?.is_terminated ? "Reports" : "Terminate Class"}
          style={{
            textTransform: "capitalize",
            borderRadius: "5px",
            color: "var(--text-secondary)",
            backgroundColor: "var(--primary)",
            height: "45px",
            padding: "0 30px",
            margin: "0 10px",
          }}
          loading={Class?.is_terminated ? false : TerminateLoading}
          click={() => {
            if (Class?.is_terminated)
              navigate(`/classes/${Class?.id}/report`);
            else setGoSave(true);
          }}
        />
        <PrimaryButton
          classBtn={"center classButton"}
          text={"Sessions"}
          style={{
            textTransform: "capitalize",
            borderRadius: "5px",
            color: "var(--text-secondary)",
            backgroundColor: "var(--primary)",
            height: "45px",
            padding: "0 30px",
            margin: "0 10px",
          }}
          click={() => {
            navigate(`/classes/${Class?.id}/sessions`);
          }}
        />

        {!Class?.is_terminated ? (
          <>
            <PrimaryButton
              classBtn={"center classButton"}
              text={"Edit Class"}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "var(--primary)",
                height: "45px",
                padding: "0 30px",
                margin: "0 10px",
              }}
              click={() => setOpen(true)}
            />
            <PrimaryButton
              classBtn={"center classButton deleteBtn"}
              text={"Delete Class"}
              click={() => setGo(true)}
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                color: "var(--text-secondary)",
                backgroundColor: "#D11A2A",
                height: "45px",
                padding: "0 30px",
                margin: "0 0 0 10px",
              }}
            />
          </>
        ) : (
          ""
        )}
      </div>) : ""}
      <div className="classDetailsCont cont">
        <FormModal
          Open={Open}
          HandleClose={() => setOpen(false)}
          Form={
            <AddClassForm
              Edit={true}
              setOpen={setOpen}
              Class={Class}
              url={url}
              setClass={setClass}
              role={role}
            />
          }
          Title={"Edit Class"}
        />
        <FormModal
          Open={StudentsOpen}
          HandleClose={() => setStudentsOpen(false)}
          Form={
            <AddStudentsToClassForm
              setOpen={setStudentsOpen}
              url={studentUrl}
              Class={Class}
              setStudents={setStudents}
            />
          }
          Title={"Add Students"}
        />
        {Class && !loading ? (
          <>
            {/* <div className="ClassDetailsBox">
            </div> */}
            <h1>{Class?.class_name}</h1>
            <h2>Program: {Class?.course?.name}</h2>
            <h2>School: {Class?.school?.name}</h2>
            <h2>Teacher: {Class?.teacher?.name}</h2>
          </>
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
      </div>
      <div className="classDetailsCont cont">
        {students && !studentsLoading ? (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <h1>Students: </h1>
              {!Class?.is_terminated ? (
                <PrimaryButton
                  classBtn={"center addButton"}
                  text={"Add Student"}
                  style={{
                    textTransform: "capitalize",
                    borderRadius: "5px",
                    color: "var(--text-secondary)",
                    backgroundColor: "var(--primary)",
                    height: "45px",
                    padding: "0 30px",
                    minWidth: "100px",
                    textWrap: "noWrap",
                  }}
                  click={() => setStudentsOpen(true)}
                />
              ) : (
                ""
              )}
            </div>
            {students?.length ? (
              <>
                <div className="studentsCont">
                  {students?.map((student) => (
                    <StudentCard
                      student={student}
                      setStudents={setStudents}
                      is_terminated={Class?.is_terminated}
                    />
                  ))}
                </div>
              </>
            ) : (
              <h2 style={{ textAlign: "center", margin: "50px" }}>
                No Students Enrolled!!
              </h2>
            )}
          </>
        ) : (
          <div className="loadingBox">
            <PulseLoader color="#06abc9" />
          </div>
        )}
      </div>
    </div>
  );
}

export default ClassesDetails;
