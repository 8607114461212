import React, { useEffect, useState } from "react";
import "./AddSessionForm.css";
import PrimaryButton from "../../../Atoms/Buttons/Primary/PrimaryButton";
import useSave from "../../../../Hooks/useSave";
import { Formik } from "formik";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextArea from "../../../Atoms/Inputs/TextArea/TextArea";
import * as Yup from "yup";

const sessionSchema = Yup.object().shape({
  content: Yup.string().required("Content required !!"),
});

function AddSessionForm({
  Edit = false,
  url,
  setOpen,
  setSessions,
  page,
  search,
}) {
  const [newObject, setNewObject] = useState({});
  const [sessionDate, setSessionDate] = useState("");
  const [goSave, setGoSave] = useState(false);
  const { saveState, loading: addLoading } = useSave({
    url: url,
    goSave,
    setGoSave,
    newObject,
    setNewData: {
      function: setSessions,
    },
  });

  if (saveState.dataRes.status == "success") {
    setOpen(false);
  }

  return (
    <div className="CourseSmallFormWrapper">
      <Formik
        validationSchema={sessionSchema}
        initialValues={{
          content: "",
        }}
        onSubmit={(values) => {
          setNewObject({ ...values, dateTime: sessionDate.add(1, "day") });
          setGoSave(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <div className="ClassSessionFormContainer">
              <br />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label="Session Date"
                    value={sessionDate}
                    onChange={(newValue) => {
                      setSessionDate(newValue);
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
              <label className="aboutLabel" style={{ paddingTop: "20px" }}>
                Session Content:
              </label>
              <TextArea
                classes="textAreaInputLarge"
                label={"Session Content"}
                name={"content"}
                change={handleChange}
                blur={handleBlur}
                newValue={values.content}
              />
              {errors.content && touched.content && errors.content && (
                <p className="errorTop">
                  {errors.content && touched.content && errors.content}
                </p>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                classBtn={"center addButton"}
                btnType={"submit"}
                text={Edit ? "Edit" : "Add"}
                loading={addLoading}
                style={{
                  textTransform: "capitalize",
                  marginTop: "15px",
                  width: "200px",
                  borderRadius: "5px",
                  color: "var(--text-secondary)",
                  backgroundColor: "var(--primary)",
                }}
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default AddSessionForm;
