import "./Schools.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import AddSchoolForm from "../../../Components/Organisms/Forms/AddSchoolForm/AddSchoolForm";
import { PulseLoader } from "react-spinners";
import { BaseURL, schoolURL } from "../../../Hooks/URL";
import ApartmentIcon from "@mui/icons-material/Apartment";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import { Fetch } from "../../../Hooks/Fetch";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Menu, MenuItem, Pagination } from "@mui/material";
import { useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import Filter from "../../../Components/Molecules/Filter/Filter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#06abc9",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  {
    id: "logo",
    label: "",
    minWidth: 70,
  },
  { id: "name", label: "Name", minWidth: 150 },
  {
    id: "email",
    label: "Email Address",
    minWidth: 150,
  },
  { id: "phone", label: "Phone Number", minWidth: 150 },
  { id: "country", label: "Country", minWidth: 100 },
  { id: "city", label: "City", minWidth: 100 },
  { id: "activated", label: "Activated", maxWidth: 50, align: "center" },
];

function Schools({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  searchData,
  search,
  setSearchData,
  setSearch,
  schools,
  setSchools,
  navigate,
  handleChangePage,
  Open,
  loading,
  setOpen,
  data,
  error,
  pageCount,
  setSchoolsLoading,
  setFilter,
}) {
  return (
    <div className="mainContainer">
      <div className="cont">
        <FormModal
          Open={Open}
          HandleClose={() => {
            setOpen(false);
          }}
          Form={
            <AddSchoolForm
              Edit={false}
              setOpen={setOpen}
              setSchools={setSchools}
              schools={schools}
              setSchoolsLoading={setSchoolsLoading}
              page={page}
              search={search}
            />
          }
          Title={"Add New School"}
        />
        <div className="header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextFieldInput
              searchIcon={true}
              Required={false}
              type="text"
              placeholder={"Search"}
              className="SearchBar"
              name={"search"}
              change={(e) => setSearchData(e.target.value)}
              newValue={searchData}
              searchAction={() => {
                if (search != searchData) {
                  setSearch(searchData);
                }
              }}
              keyDown={(e) => {
                if (e.keyCode === 13)
                  if (search != searchData) {
                    setSearch(searchData);
                  }
              }}
            />
            <Filter country city activated setFilter={setFilter} />
          </div>
          <PrimaryButton
            classBtn={"center addButton"}
            text={"Add School"}
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--primary)",
              height: "45px",
              padding: "0 30px",
              minWidth: "100px",
              textWrap: "noWrap",
            }}
            click={() => {
              setOpen(true);
            }}
          />
        </div>
        <Paper
          className="tableCont"
          sx={{ width: "fit-content", minWidth: "100%" }}
        >
          {schools && !loading ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schools.map((school) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={school.code}
                          onClick={() => {
                            navigate(`/schools/${school.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = school[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ padding: "10px" }}
                              >
                                {column.id === "logo" ? (
                                  value ? (
                                    <img
                                      alt="logo"
                                      width={"50px"}
                                      height={"50px"}
                                      src={`${BaseURL}/images/${value}`}
                                      style={{
                                        margin: "4px 0 0 0",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  ) : (
                                    <ApartmentIcon
                                      style={{
                                        fontSize: "35px",
                                        color: "#06abc9",
                                        margin: "7px 0 0 8px",
                                      }}
                                    />
                                  )
                                ) : column.id === "activated" ? (
                                  value ? (
                                    <DoneIcon className="activatedTick" />
                                  ) : (
                                    <CloseIcon className="NonActivatedTick" />
                                  )
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!schools?.length ? (
                  <h3 style={{ textAlign: "center", margin: "50px" }}>
                    No Schools Added Yet!!
                  </h3>
                ) : (
                  ""
                )}
              </TableContainer>
              <Pagination
                className="paginationCount paginationCountMargin"
                count={pageCount}
                page={page + 1}
                onChange={handleChangePage}
                size="large"
              />
              {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </>
          ) : (
            <div className="loadingBox">
              <PulseLoader color="#06abc9" />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default Schools;
