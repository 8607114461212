import React, { useEffect, useState } from "react";
import { classesURL, reportsClassesURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import ClassReport from "../Templates/Home/Classes/ClassReport/ClassReport";
import { useNavigate, useParams } from "react-router-dom";
import useUpdate from "../Hooks/useUpdate";

function ClassReportPage() {
  let { id } = useParams();
  const navigate = useNavigate();

  const url = `${classesURL}/${id}`;
  const studentUrl = `${reportsClassesURL(id)}/students`;
  const terminateUrl = `${reportsClassesURL(id)}/checkTerminateToManageReports`;

  const [reportsVisible, setReportsVisible] = useState(false);

  const [students, setStudents] = useState([]);
  const {
    data: studentsData,
    loading: studentsLoading,
    error: studentsError,
  } = useFetch(studentUrl);
  const { data, loading, error } = useFetch(url);
  const [Class, setClass] = useState({});
  const {
    data: terminateData,
    loading: terminateLoading,
    error: terminateError,
    status: terminateStatus,
  } = useFetch(terminateUrl);

  const [goEdit, setGoEdit] = useState(false);
  const { loading: editLoading } = useUpdate(
    !reportsVisible
      ? `${reportsClassesURL(id)}/visibility/hideReports`
      : `${reportsClassesURL(id)}/visibility/showReports`,
    goEdit,
    setGoEdit
  );

  useEffect(() => {
    if (terminateStatus == "fail") navigate(`/classes/${id}`);
  }, [terminateData, terminateLoading, terminateError]);

  useEffect(() => {
    if (!loading && !error && data) {
      setClass(data.data);
      setReportsVisible(data.data?.reports_visible);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!studentsLoading && !studentsError && studentsData) {
      setStudents(studentsData.data);
    }
  }, [studentsData, studentsLoading, studentsError]);

  return (
    <ClassReport
      Class={Class}
      classId={id}
      students={students}
      studentsLoading={studentsLoading}
      reportsVisible={reportsVisible}
      setReportsVisible={setReportsVisible}
      setGoEdit={setGoEdit}
      setStudents={setStudents}
      studentUrl={studentUrl}
    />
  );
}

export default ClassReportPage;
